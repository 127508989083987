import React from "react";

import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import {
  ARROW,
  Arrow,
  AutoPlay,
  Fade,
  Pagination,
} from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import "@egjs/flicking-plugins/dist/flicking-plugins.css";
import Images from "../../Components/Atoms/Images";
import slide1 from "../../assets/verdeAssets/carousel/slide1.jpg";
import slide1Bg from "../../assets/verdeAssets/carousel/slide1-bg.jpg";

export default function Carousel() {
  const pagination = new Pagination({ type: "bullet" });
  const autoplay = new AutoPlay({
    duration: 2000,
    direction: "NEXT",
    stopOnHover: false,
  });
  const arrow = new Arrow();
  const fade = new Fade();

  const plugins = [arrow, autoplay];
  const plugins1 = [fade];
  return (
    <div>
      <Flicking
        panelsPerView="2"
        // plugins={plugins}
        duration="2500"
        defaultIndex="0"
        circular="true"
        interruptable="true"
        className="verde__carousel__flicking "
      >
        <div className="slides__flicking">
          <div className="slide">
            <Images className="slide__bg" image={slide1Bg} alt="" fullwidth />
            <Images image={slide1} alt="" fullwidth />
          </div>
        </div>
        {/* <div className="slides__flicking">
          <div className="slide">
            <Images className="slide__bg" image={slide1Bg} alt="" fullwidth />
            <Images image={slide1} alt="" fullwidth />
          </div>
        </div>
        <div className="slides__flicking">
          <div className="slide">
            <Images className="slide__bg" image={slide1Bg} alt="" fullwidth />
            <Images image={slide1} alt="" fullwidth />
          </div>
        </div> */}

        <ViewportSlot>
          <span className="flicking-pagination"></span>
          {/* <span className="flicking-arrow-prev"></span>
          <span className="flicking-arrow-next"></span> */}
        </ViewportSlot>
      </Flicking>
    </div>
  );
}
