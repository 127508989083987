import React from "react";

//components
import PortfolioHeading from "../Components/PortfolioHeading";
import PrevAndNextWork from "../Components/PrevAndNextWork";
import Footer from "../Components/Footer";
import FlickingCarousel from "../Components/FlickingCarousel";
import CardsNums from "../Components/Atoms/CardsNums";
import Heading from "../Components/Atoms/Heading";
import CardsImage from "../Components/Atoms/CardsImage";
import Images from "../Components/Atoms/Images";
import WebsiteGlimpse from "../Components/Atoms/WebsiteGlimpse";
import { AiOutlineSearch } from "react-icons/ai";

//images
import vijay from "../assets/images/VCH.jpg";
import encompass from "../assets/images/encompass.jpg";

//Video
import Evre_video from "../assets/evreAssets/Evre_video.mp4";

import logo from "../assets/evreAssets/validating_icon.svg";
import logo1 from "../assets/evreAssets/business_type_icon.svg";
import businessgoals from "../assets/evreAssets/Business_Goals.svg";
import targetusers from "../assets/evreAssets/Target_Users.svg";
import projectTimeline from "../assets/evreAssets/project_timeline.svg";
import bg_triangle from "../assets/evreAssets/bg_triangle.svg";
import user_personal from "../assets/evreAssets/bruce-mars.png";
import circled_cross from "../assets/evreAssets/empathy_map.png";
import site_map from "../assets/evreAssets/sime_map.svg";
import card_sorting from "../assets/evreAssets/card_sorting.png";
import Homepage_Glimpse from "../assets/evreAssets/homepage_glimpse.png";
import About_Glimpse from "../assets/evreAssets/about_glimpse.png";
import Infinity_Glimpse from "../assets/evreAssets/A_InfinityGlimpse.png";
import Screens from "../assets/evreAssets/Screens.png";
import car_section from "../assets/evreAssets/Footer.svg";
import visualPhase1 from "../assets/evreAssets/visualPhase01.png";
import visualPhase2 from "../assets/evreAssets/visualPhase02.png";
import visualPhase3 from "../assets/evreAssets/visualPhase03.png";
import FinalOutcome from "../assets/evreAssets/FinalOutcome.png";
import smile_icon from "../assets/evreAssets/smile_icon.svg";
import Customer_journey01 from "../assets/evreAssets/Customer_journey_map001.png";
import Customer_journey02 from "../assets/evreAssets/Customer_journey_map002.png";

// PrevAndNextWork Images
import ima from "../assets/images/Ima.jpg";
import sleek from "../assets/images/sleek_new.jpg";

//data
import { wireframes } from "./PortfoliosDATA";

//logo
import a1 from "../assets/evreAssets/logos/a1.svg";
import a2 from "../assets/evreAssets/logos/a2.svg";
import a3 from "../assets/evreAssets/logos/a3.svg";
import a4 from "../assets/evreAssets/logos/a4.svg";

//components

const evreData = [
  {
    image: a1,
  },
  {
    image: a2,
  },
  {
    image: a3,
  },
  {
    image: a4,
  },
  {
    image: a4,
  },
  {
    image: a4,
  },
  {
    image: a4,
  },
  {
    image: a4,
  },
];

const BgTriangle = ({ className }) => {
  return (
    <div className={className}>
      <img className="image" src={bg_triangle} />
    </div>
  );
};

const SmallCards = ({ className, number, bigHead, head, para }) => {
  return (
    <div className={className}>
      {number ? (
        <div className="number__box">
          <h4> {number} </h4>
        </div>
      ) : (
        ""
      )}
      <h3>{bigHead}</h3>
      <p>{head}</p>
      <h5>{para}</h5>
    </div>
  );
};

const Evre = () => {
  return (
    <div className="portfolio__container">
      <PortfolioHeading
        heading="EVRE"
        subHeading="UI/UX"
        info="EVRE is an Indian OEM and EV-charging infrastructure developer. 
        The Bengaluru-based company has 550+ charging stations covering prime commercial, industrial, and 
        residential locations across India. The company is one of the
         fastest-growing players in the sector owing to its patented tech and expanding outreach. A strong digital presence 
         was imminent, given the company’s vision and rising competition. With diverse B2B and B2C-centric 
         products and services, revamping their website was inarguably the first step."
      />

      {/* <div className="img__max__width">
            <img src={laptop} alt="bird" />
          </div> */}

      {/* <h3>Overview</h3> */}

      <div className="portfolio__content">
        <div className="evre__main__container">
          <Heading
            textClass="evre__text__gradient"
            logo={logo}
            Heading="The problem statement"
            para="The first step for us was collecting knowledge about EVRE and the problems that our efforts needed to address.
            "
          />

          <div className="card__banner top__margin">
            <CardsNums
              className="card__black cards__nums__container"
              para="360° appeal"
              para1="The offerings ranged from turnkey revenue-sharing charging hubs to individual EV charging stations. The website needed to appeal to both B2B and B2C consumers."
              number="01"
            />
            <CardsNums
              className="card__black cards__nums__container"
              para="Divide And Rule"
              para1="To make it simple for users, the website needed clear bifurcation of products and services to the T. This called for individual pages for each.
              "
              number="02"
            />
            <CardsNums
              className="card__black cards__nums__container"
              para="The functionality quotient"
              para1="Functions like shopping, EV charging station locator, payment integration, chatbot, etc. were a must. They needed to run like a hot knife through butter.
              "
              number="03"
            />
            <CardsNums
              className="card__black cards__nums__container"
              para="Adding to the looks"
              para1="The assets included 180° images of products with seamless viewing on mobile phones. The most optimized coding and design practices were to be sought.
              "
              number="04"
            />

            <CardsNums
              className="card__black cards__nums__container"
              para="Content as a supplement"
              para1="Contrary to conventional tech websites, the need was for minimum content and maximum impact despite the high-end technical specs."
              number="05"
            />
            <CardsNums
              className="card__black cards__nums__container"
              para="Customized designs"
              para1="With varying use cases, the website demanded vector graphics in tandem with each use case. Bespoke graphics were to be planned and created for the same."
              number="06"
            />
          </div>

          <div className="top__margin">
            <Heading
              textClass="evre__text__gradient"
              logo={logo1}
              Heading="Business model"
            />
          </div>

          {/* Business Type  */}

          <div className="evre__main__box">
            <div className="half__left evre__left__box">
              <h4>B2C:</h4>
              <p>EVSE for:</p>
              <ul className="business__type__list">
                <li>personal use</li>
                <li>residential societies</li>
                <li>leased lands</li>
                <li>revenue-sharing charging stations</li>
              </ul>
              <p>EV charging services for:</p>
              <ul className="business__type__list">
                <li>parking facilities</li>
                <li>public EV charging points</li>
                <li>
                  small businesses (co-working space, restaurant, library, etc.)
                </li>
              </ul>
            </div>

            <div className="separator"></div>

            <div className="half__right evre__right__box">
              <h4>B2B:</h4>
              <p>EVSE and EV charging services for:</p>
              <ul className="business__type__list">
                <li>large-scale industries</li>
                <li>logistics and fleet operators</li>
                <li>government offices</li>
                <li>businesses</li>
                <li>shopping malls</li>
                <li>multiplex chains</li>
              </ul>
            </div>
          </div>

          {/* Design Strategy */}

          <div className="top__margin">
            <Heading
              textClass="evre__text__gradient"
              logo={logo1}
              Heading="Design strategy"
              para="Based on the information and understanding gathered, we chalked out a strategy covering major areas. The product and services were the same, its implementation was use-case dependent with tweaks in the product configurations.
            "
            />
          </div>

          <div className="evre__design__strategy">
            <CardsImage
              className={"card__Images"}
              uls="Business Goals"
              li1="The goal was to generate leads with a clear user interaction experience. So we dissected the site into products and services."
              image={businessgoals}
            />
            <div className="dark__line"></div>
            <CardsImage
              className={"card__Images"}
              uls="Target Users"
              li1="The diverse target base was narrowed down into individual, commercial, and large-scale EVSE prospects. This enabled easy navigation for users.
              "
              image={targetusers}
            />
            <div className="dark__line"></div>
            <CardsImage
              className={"card__Images"}
              uls="Content division"
              li1="The content had to be concise based on a cut-to-the-chase approach. There were detailed specs to be shared without unnecessary puffing."
              image={businessgoals}
            />
          </div>
        </div>

        <div className="evre__main__container__black ">
          {/* <Heading
            textClass="evre__text__gradient"
            Heading="A Look at some competitors"
          />

          <FlickingCarousel
            evreData={evreData}
            className="evre__flicking__carousels"
          /> */}

          {/* Project Timeline */}

          <Heading
            textClass="evre__text__gradient"
            Heading="Project timeline"
            // para={"See how our project has ended over time"}
          />

          <Images className="evre__top__less__margin" image={projectTimeline} />
        </div>

        <div className="evre__main__container">
          <Heading
            textClass="evre__text__gradient"
            // para1="OUR ROLE"
            Heading="Our role"
          />

          <div className="cards__4__container">
            <CardsImage
              className={"cards__4"}
              uls="Research"
              li1="User survey"
              li2="User personal"
              icons={<AiOutlineSearch size={36} />}
            />
            <CardsImage
              className={"cards__4"}
              uls="UX Design"
              li1="User flow"
              li2="Wireframes"
              icons={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="27"
                  viewBox="0 0 33 27"
                >
                  <g transform="translate(-1.5 -4.735)">
                    <path
                      d="M6,6H30a3.009,3.009,0,0,1,3,3V27a3.009,3.009,0,0,1-3,3H6a3.009,3.009,0,0,1-3-3V9A3.009,3.009,0,0,1,6,6Z"
                      transform="translate(0 0.235)"
                      fill="none"
                      stroke="#00d176"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                    />
                    <path
                      d="M8699.765,7990.2l.662-.52-14.055-10.649,14.086,10.624,13.938-10.937-13.905,10.962,14.119,10.649-13.428-10.078-.723-.546-13.873,10.936Z"
                      transform="translate(-8682.344 -7971.481)"
                      fill="none"
                      stroke="#00d176"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                    />
                  </g>
                </svg>
              }
            />
            <CardsImage
              className={"cards__4"}
              uls="UI Design"
              li1="Style guide"
              li2="Visual design"
              icons={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33.621"
                  height="33.621"
                  viewBox="0 0 33.621 33.621"
                >
                  <g transform="translate(-0.879 -0.879)">
                    <path
                      d="M18,28.5,28.5,18,33,22.5,22.5,33Z"
                      fill="none"
                      stroke="#00d176"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                    />
                    <path
                      d="M27,19.5,24.75,8.25,3,3,8.25,24.75,19.5,27Z"
                      fill="none"
                      stroke="#00d176"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                    />
                    <path
                      d="M3,3,14.379,14.379"
                      fill="none"
                      stroke="#00d176"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                    />
                    <path
                      d="M19.5,16.5a3,3,0,1,1-3-3A3,3,0,0,1,19.5,16.5Z"
                      fill="none"
                      stroke="#00d176"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                    />
                  </g>
                </svg>
              }
            />
            <CardsImage
              className={"cards__4"}
              uls="Interactions"
              li1="Clickable prototype"
              li2="Micro-interactions"
              icons={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="38"
                  viewBox="0 0 30 38"
                >
                  <g transform="translate(-248 -42)">
                    <g
                      transform="translate(248 42)"
                      fill="none"
                      stroke="#00d176"
                      stroke-width="2"
                    >
                      <rect width="30" height="38" rx="15" stroke="none" />
                      <rect
                        x="1"
                        y="1"
                        width="28"
                        height="36"
                        rx="14"
                        fill="none"
                      />
                    </g>
                    <line
                      y2="5"
                      transform="translate(263.5 52.5)"
                      fill="none"
                      stroke="#00d176"
                      stroke-linecap="round"
                      stroke-width="3"
                    />
                  </g>
                </svg>
              }
            />
          </div>

          {/* // USER SURVEY */}

          {/* <div style={{ position: "relative" }}>
            <Heading
              textClass="evre__text__gradient"
              className="user__survey__heading evre__top__less__margin"
              para1="USER SURVEY"
              HeadingLeft="For our research process, we decided to do interviews & surveys to better 
              understand the needs of the end users."
              HeadingLeft_1="Surveys are a great first look at the customer environment because they provide quantitative data."
            />
            <BgTriangle className="pos__bg__triangle" />
          </div> */}

          {/* ############ Interviews ################ */}

          {/* <div className="black__banner evre__main__container__black">
            <div className="black__banner__in">
              <p>User Interviews</p>

              <hr />

              <h3 className="evre__text__gradient ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </h3>
            </div>

            <div className="black__banner__in top__margin">
              <p>Understanding</p>

              <hr />

              <h3 className="evre__text__gradient ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </h3>
            </div>

            <div className="evre__small__cards__wrapper">
              <SmallCards
                className="evre__small__cards"
                number="01"
                head="Lorem ipsum"
                para="ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf"
              />
              <SmallCards
                className="evre__small__cards"
                number="02"
                head="Lorem ipsum"
                para="ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf"
              />
              <SmallCards
                className="evre__small__cards"
                number="03"
                head="Lorem ipsum"
                para="ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf ssfsdfd sdfsdf sfsdfsdf fsdfsdfs sdfsdf"
              />
            </div>

            <BgTriangle className="pos__bg__triangle__bottom" />
          </div> */}

          {/* USER PERSONAL */}

          {/* <div className="user__personal__main__wrapper">
            <div className="user__personal__wrapper">
              <Heading
                textClass="evre__text__gradient"
                Heading="Its all about user data"
                para1={"USER PERSONAL"}
                bottomImage={user_personal}
                className="user__personal__heading"
              />
            </div>
          </div> */}

          {/* <div className="user__personal__head__para">
            <SmallCards
              className="evre__small__cards"
              bigHead="Personality"
              head="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            />

            <div className={"cards__2__wrapper"}>
              <CardsImage
                className={"cards__2"}
                uls="Need"
                li1="Eiusmod tempor incididunt ut"
                li2="Lorem ipsume dolor sit amet ut enim ad minim veniam, quis nostrud exercitation."
                li3="Labore et dolore magna aliqua. consectetur adipiscing elit"
              />
              <CardsImage
                className={"cards__2"}
                uls="Goal"
                li1="Eiusmod tempor incididunt ut"
                li2="Lorem ipsume dolor sit amet ut enim ad minim veniam, quis nostrud exercitation."
                li3="Labore et dolore magna aliqua. consectetur adipiscing elit"
              />
            </div>
          </div> */}
        </div>

        {/* ##############   EMPATHY MAP  ############# */}

        <div className="evre__main__container__black">
          <div className="empathy__map__wrapper">
            {/* ##### Empathy Map #####*/}
            <Heading
              textClass="evre__text__gradient"
              className="empathy__map__heading"
              Heading="Empathy Map"
            />

            <Images className="evre__top__less__margin" image={circled_cross} />

            {/* ##### Customer Journey Map #####*/}
            {/* <Heading
              textClass="evre__text__gradient"
              className="empathy__map__heading evre__top__margin"
              Heading="Customer Journey Map"
            />

            <Images
              className=" evre__top__less__margin"
              image={Customer_journey01}
            />
            <Images
              className=" evre__top__less__margin"
              image={Customer_journey02}
            /> */}

            {/* ##### Site Map & Flow #####*/}

            <Heading
              textClass="evre__text__gradient"
              className="empathy__map__heading evre__top__margin"
              Heading="Site-map and flow"
              // para1="ABOUT PROJECT"
            />
            <Images className="evre__top__less__margin" image={site_map} />

            {/* ##### Card Sorting #####*/}

            <Heading
              textClass="evre__text__gradient"
              className="empathy__map__heading evre__top__margin"
              Heading="Card Sorting"
            />

            <Images className="evre__top__less__margin" image={card_sorting} />
          </div>
        </div>
        {/* 
        <div className="evre__main__container padding_bottom">
          <div className="wireframes__boxes">
            {wireframes.map((item, index) => {
              return (
                <div className="wireframes__box">
                  {item.head && (
                    <div className="first__box">
                      <h3 className=" evre__text__gradient">{item.head}</h3>
                      {item.para && <p>{item.para}</p>}
                    </div>
                  )}
                  {item.title && <p>{item.title}</p>}
                  {item.image && (
                    <div className="wireframes__box__images">
                      <img src={item.image} />
                    </div>
                  )}
                  {item.subImagesLeft && (
                    <div>
                      {item.subImagesLeft.map((items, index) => {
                        return (
                          <>
                            {items.head && <h3>{items.head}</h3>}
                            {items.title && (
                              <p style={{ paddingTop: "2.5rem" }}>
                                {items.title}
                              </p>
                            )}
                            <div className="wireframes__box__images">
                              <img src={items.image} />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                  {item.subImagesRight && (
                    <div>
                      {item.subImagesRight.map((items, index) => {
                        return (
                          <>
                            {items.head && <h3>{items.head}</h3>}
                            {items.title && (
                              <p style={{ paddingTop: "2.5rem" }}>
                                {items.title}
                              </p>
                            )}
                            <div className="wireframes__box__images">
                              <img src={items.image} />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div> */}

        {/* Visual Style Guide */}

        <div
          className="evre__main__container"
          style={{ backgroundColor: "#1a1d24" }}
        >
          <Heading
            textClass="evre__text__gradient"
            // para1="VISUAL PHASE"
            Heading="Visual style guide"
          />
          <Images className="evre__top__less__margin" image={visualPhase1} />
          <br />
          <br />
          <br />
          <Images className="evre__top__less__margin" image={visualPhase2} />
          <br />
          <br />
          <br />
          <Images className="evre__top__less__margin" image={visualPhase3} />
        </div>

        {/* On Boarding Video */}

        <div className="evre__main__container__black">
          <Heading
            textClass="evre__text__gradient"
            Heading="On Boarding Video"
          />

          <video
            className="evre__top__less__margin"
            placeholder="EVRE intro Video"
            autoPlay="true"
            controls
            loop
            width="100%"
          >
            <source src={Evre_video} type="video/mp4" />
          </video>
        </div>

        {/* #########     WEBSITE GLIMPSE     #########*/}

        <div className="evre__main__container">
          <WebsiteGlimpse
            // heading1="Home page"
            heading2="Home page"
            image={Homepage_Glimpse}
          />
          <WebsiteGlimpse
            // heading1="About page"
            heading2="About page"
            // para="Magna aliqua enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            reverse
            image={About_Glimpse}
          />
          <WebsiteGlimpse
            // heading1="Product page"
            heading2="Product page"
            // para="Magna aliqua enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            image={Infinity_Glimpse}
          />
        </div>

        {/* CAR SECTION */}
        {/* <div className="evre__main__container__black">
          <SmallCards
            className="car__section__heading__small__cards "
            bigHead="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore."
            head="-Lorem ipsum."
          />

          <div className="car__section__image ">
            <img className="image" src={car_section} alt="alter" />
          </div>
        </div> */}

        {/* 38+ Screens */}
        <div className="evre__main__container padding_bottom">
          <Heading
            textClass="evre__text__gradient"
            Heading="Screens – The big 38"
            para="The screens for both products and services individually featured a brief introduction with an explore button. It was to boost users’ decision-making by subconsciously leading them to their needs.
            "
          />
          <Images
            className="evre__top__less__margin"
            image={Screens}
            fullwidth
          />
        </div>

        {/* FINAL OUTCOME */}
        {/* <div className="evre__main__container">
          <Heading
            textClass="evre__text__gradient"
            BigHeading="Result & Concluding"
            BigHeading_1="Thoughts"
            para1="FINAL OUTCOME"
          />
          <Images className="evre__top__less__margin" image={FinalOutcome} />
        </div> */}

        {/* Thanks for Watching */}

        {/* <div className="thanks__for__watching">
          <Images className="thanks__for__watching__icon" image={smile_icon} />
          <h3 className="evre__text__gradient top__margin">
            Thanks for watching
          </h3>
        </div> */}

        <PrevAndNextWork
          prev="/work/vijay-chat-house"
          next="/work/encompass"
          image1={ima}
          image2={sleek}
        />
        <Footer />
      </div>
    </div>
  );
};

export default Evre;
