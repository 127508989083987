import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// styles
import "./App.scss";

//components
import WorkPage from "./Pages/WorkPage";
import Homepage from "./Pages/Homepage";
import AboutPage from "./Pages/AboutPage";
import ServicePage from "./Pages/ServicePage";
import TeamPage from "./Pages/TeamPage";
import JoinUsPage from "./Pages/JoinUsPage";
import ContactUsPage from "./Pages/ContactUsPage";
import NavBar from "./Components/NavBar";
import PortfolioPage from "./Pages/PortfolioPage";
import Form from "./Components/Form";
import RedirectPage from "./Pages/RedirectPage";

//animation on scroll
import Aos from "aos";
import "aos/dist/aos.css";
import Error from "./Pages/Error";
import Footer from "./Components/Footer";
import HomePage from "./Pages/Homepage";
Aos.init();

//function
function App() {
  const location = useLocation();

  const Redirect = () => {
    return (window.location.href = "https://blacksof.com");
  };

  return (
    <div className="App">
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <Redirect />
            <div className="placeholder__dark"></div>
            {/* <WorkPage /> */}
          </Route>
          <Route path="/work" exact>
            <Redirect />
            <div className="placeholder__dark"></div>
            {/* <WorkPage /> */}
          </Route>
          <Route path="/work/:id" exact>
            <NavBar />
            <PortfolioPage />
          </Route>
          {/* <Route path="/work" exact>
            <WorkPage />
          </Route>
         
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/service">
            <ServicePage />
          </Route>
          <Route path="/team">
            <TeamPage />
          </Route>
          <Route path="/join">
            <JoinUsPage />
          </Route>
          <Route path="/Form">
            <Form />
          </Route>
          <Route path="/contact">
            <ContactUsPage />
          </Route> */}
          <Route>
            <Error path="*" />
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  );
}

export default App;
