import React from "react";

//components
import PortfolioHeading from "../Components/PortfolioHeading";
import PrevAndNextWork from "../Components/PrevAndNextWork";

//images
import vijay from "../assets/images/VCH.jpg";
import itat from "../assets/images/ITAT.jpg";

import logo from "../assets/Informatic press (markplus) Assests/Image 56.png";
import book01 from "../assets/Informatic press (markplus) Assests/Image 112.png";
import fruit01 from "../assets/Informatic press (markplus) Assests/Image 113.png";
import book02 from "../assets/Informatic press (markplus) Assests/Group 1581.png";
import book03 from "../assets/Informatic press (markplus) Assests/Group 703.png";
import book04 from "../assets/Informatic press (markplus) Assests/Group 1582.png";
import book05 from "../assets/Informatic press (markplus) Assests/Group 1586.png";
import book06 from "../assets/Informatic press (markplus) Assests/Group 1585.png";
import book07 from "../assets/Informatic press (markplus) Assests/Group 1583.png";
import book08 from "../assets/Informatic press (markplus) Assests/Group 1593.png";
import book09 from "../assets/Informatic press (markplus) Assests/Book-Mockup-Hardcover-Vol3.png";
import book10 from "../assets/Informatic press (markplus) Assests/Group 1584.png";
import book11 from "../assets/Informatic press (markplus) Assests/Group 700.png";
import book12 from "../assets/Informatic press (markplus) Assests/Mask Group 30.png";
import book14 from "../assets/Informatic press (markplus) Assests/Mask Group 29.png";
import book13 from "../assets/Informatic press (markplus) Assests/Paperback Cover book cover 04.png";
import Footer from "../Components/Footer";

const InformaticsPress = () => {
  return (
    <>
      <div className="portfolio__container">
        <PortfolioHeading
          heading="Informatics Press"
          subHeading="Branding"
          info="Informatics Press, using its newly-launched publishing vertical, wanted to launch an innovative 
          range of notebooks for Tier 1, 2, and 3 city users. Each notebook, with different sets of facts and 
          insights to resonate with their diverse target audience."
          infoSecondary="Informatics Press partnered with Blacksof to conduct market research and 
          strategize the product mix to conceptualize and design more than 130 plus notebook concepts ranging in 
          various sizes, quality, and price ranges. Blacksof conceptualized unique themes to launch 4 categories 
          of notebooks to ensure increased brand awareness, loyalty, and repeat customers.
          "
        />

        <div className="portfolio__content">
          <div className="informatics__logo">
            <img src={logo} alt="informatics logo" />
          </div>
          <div className="img__max__width">
            <img src={book01} alt="book" />
          </div>

          <div className="img__max__width">
            <img src={fruit01} alt="fruit" />
          </div>

          <div className="alignment__container">
            <div className="book02">
              <img src={book02} alt="book" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={book03} alt="book" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={book04} alt="book" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={book05} alt="book" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={book06} alt="book" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={book07} alt="book" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={book08} alt="book" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={book09} alt="book" />
            </div>

            <div className="left__align bottom__margin">
              <img src={book10} alt="book" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={book11} alt="book" />
            </div>

            <div className="right__align bottom__margin">
              <img src={book12} alt="book" />
            </div>

            <div className="left__align bottom__margin">
              <img src={book13} alt="book" />
            </div>

            <div className="right__align bottom__margin">
              <img src={book14} alt="book" />
            </div>

            <div style={{ display: "inline-block" }}></div>
          </div>
          <PrevAndNextWork
            prev="/work/vijay-chat-house"
            next="/work/itat"
            image1={vijay}
            image2={itat}
          />

          <Footer />
        </div>
      </div>
    </>
  );
};

export default InformaticsPress;
