import React from "react";

//componets
import PortfolioHeading from "../Components/PortfolioHeading";

//images
import ima from "../assets/images/Ima.jpg";
import sleek from "../assets/images/sleek_new.jpg";

import logo from "../assets/Ima 2020 assests/Group 2515.png";
import vedio from "../assets/Ima 2020 assests/Group 2518.png";
import icon from "../assets/Ima 2020 assests/Group 2308.png";
import trillon from "../assets/Ima 2020 assests/Group 2514.png";
import graph from "../assets/Ima 2020 assests/Group 2384.png";
import flag from "../assets/Ima 2020 assests/Mask Group 12.png";
import bag from "../assets/Ima 2020 assests/Group 2550.png";
import mug from "../assets/Ima 2020 assests/Mask Group 15.png";
import bands from "../assets/Ima 2020 assests/Group 2510.png";
import stage from "../assets/Ima 2020 assests/Group 2511.png";
import brochure from "../assets/Ima 2020 assests/Group 2513.png";
import hording from "../assets/Ima 2020 assests/Group 2512.png";
import event from "../assets/Ima 2020 assests/Group 2508.png";
import newspaper from "../assets/Ima 2020 assests/57301.png";

import Footer from "../Components/Footer";
import PrevAndNextWork from "../Components/PrevAndNextWork";

const IMA2020 = () => {
  return (
    <>
      <div className="portfolio__container">
        <PortfolioHeading
          heading="IMA 2020"
          subHeading="Event Branding"
          info="Indore Management Association is a nationally acclaimed 60+ years old body of entrepreneurs that aims
           to uplift local and national business landscape across India. IMA Conclave is their flagship annual conference 
           that attracts thousands of leaders, entrepreneurs, students, and business enthusiasts at one junction to discuss 
           breakthroughs, challenges, and the global economic landscape. 
          "
          infoSecondary="IMA stakeholders joined hands with Blacksof for the second time in a row to brand the 
          conference on the theme ‘5 Trillion Economy.’ Blacksof took the charge to create 100+ intellectually 
          stimulating event branding collaterals and oversaw the entire on-ground execution to make the conference a success. 
          "
        />

        <div className="portfolio__content">
          <div className="ima2020__logo">
            <img src={logo} alt="ima logo" />
          </div>

          <div className="full__img">
            <img src={vedio} alt="vedio" />
          </div>

          <div className="alignment__container">
            <div className="ima__icon">
              <img src={icon} alt="icon" />
            </div>

            <div className="img__max__width">
              <img src={trillon} alt="trillon" />
            </div>

            <div className="graph">
              <img src={graph} alt="bar" />
            </div>

            <div className="img__max__width flag">
              <img src={flag} alt="flag" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={bag} alt="bag" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={mug} alt="bag" />
            </div>
          </div>

          <div className="full__img">
            <img src={bands} alt="bands" />
          </div>

          <div className="full__img stage">
            <img src={stage} alt="bands" />
          </div>

          <div className="alignment__container">
            <div className="img__max__width">
              <img src={brochure} alt="brochure" />
            </div>

            <div className="img__max__width stage">
              <img src={hording} alt="brochure" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={event} alt="event" />
            </div>

            <div className="img__max__width bottom__margin">
              <img src={newspaper} alt="newspaper" />
            </div>
          </div>
          <PrevAndNextWork
            prev="/work/sleek"
            next="/work/ima2019"
            image1={sleek}
            image2={ima}
          />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default IMA2020;
