import React, { useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { gsap } from "gsap/all";
import Hamburger from "hamburger-react";

//import images
import logo from "../assets/images/blacksofLogo.png";
import logoWhite from "../assets/images/logo_white.svg";
import hamburger from "../assets/images/Group 6.png";
import cross from "../assets/images/cancel.svg";

const NavBar = () => {
  //hamburger
  const [isOpen, setOpen] = useState(false);

  let mobile_Ref = useRef(null);
  let logoRef = useRef(null);
  let logoRefBlack = useRef(null);
  let navLink_Ref = useRef([]);

  const handleToggle = () => {
    if (isOpen === false) {
      gsap.to(mobile_Ref, { opacity: 1, duration: 0.2, display: "block" });
      gsap.fromTo(
        navLink_Ref.current,
        { opacity: 1, y: 55 },
        { delay: 0.1, duration: 1, y: 0 }
      );
      gsap.to(logoRef, { y: 100, duration: 0.6 });
      gsap.fromTo(logoRefBlack, { y: 100 }, { y: 0, duration: 0.6 });
      setOpen(true);
    } else {
      gsap.to(mobile_Ref, {
        delay: 0.2,
        display: "none",
        opacity: 0,
        duration: 0.5,
      });
      gsap.to(navLink_Ref.current, { duration: 0.6, y: -55 });
      gsap.to(logoRef, { y: 0, duration: 0.6 });
      gsap.to(logoRefBlack, { y: 100, duration: 0.6 });

      setOpen(false);
    }
  };

  const navlink = [
    {
      link: "work",
      text: "Work",
    },
    {
      link: "about",
      text: "About",
    },
    {
      link: "service",
      text: "Service",
    },
    {
      link: "team",
      text: "Team",
    },
    {
      link: "join",
      text: "Join",
    },
    {
      link: "contact",
      text: "Contact",
    },
  ];

  return (
    <>
      <nav className="nav">
        <div className="navbar__container">
          <div className="logo">
            <a href="https://blacksof.com">
              <img
                className="image"
                src={logo}
                alt="blacksof_logo"
                ref={(ele) => (logoRef = ele)}
              />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
