import React from "react";

//components
import PortfolioHeading from "../../Components/PortfolioHeading";
import PrevAndNextWork from "../../Components/PrevAndNextWork";
import Footer from "../../Components/Footer";

//  assets import
import blade_2 from "../../assets/verdeAssets/verde-hero.jpg";
import blade_3 from "../../assets/verdeAssets/verde-product-features.jpg";
import blade_4 from "../../assets/verdeAssets/typo-colors.jpg";
import blade_5 from "../../assets/verdeAssets/verde-branding.jpg";
import blade_6 from "../../assets/verdeAssets/verde-design.jpg";

import blade_7 from "../../assets/verdeAssets/verde-demo-1.jpg";
import blade_8 from "../../assets/verdeAssets/verde-demo-2.jpg";
import blade_9 from "../../assets/verdeAssets/verde-demo-3.jpg";
import blade_10 from "../../assets/verdeAssets/verde-demo-4.jpg";
import blade_11 from "../../assets/verdeAssets/verde-demo-5.jpg";
import blade_12 from "../../assets/verdeAssets/verde-demo-6.jpg";

import blade_14 from "../../assets/verdeAssets/verde-mobile-app.jpg";
import blade_13 from "../../assets/verdeAssets/social-media.jpg";
import blade_15 from "../../assets/verdeAssets/verde-animation.jpg";
import blade_16 from "../../assets/verdeAssets/verde-mobile-1.jpg";
import blade_17 from "../../assets/verdeAssets/verde-mobile-2.jpg";
import blade_18 from "../../assets/verdeAssets/verde-mobile-3.jpg";
import blade_19 from "../../assets/verdeAssets/verde-posts-1.jpg";
import blade_20 from "../../assets/verdeAssets/verde-posts-2.jpg";
import blade_21 from "../../assets/verdeAssets/verde-foots.jpg";

//images
import prevImg from "../../assets/images/IP.jpg";
import nextImg from "../../assets/images/ITAT.jpg";

//  atoms
import Images from "../../Components/Atoms/Images";
import Carousel from "./carousel";

const InfiniteCarousel = ({ className }) => {
  return (
    <>
      <div className={`infinite-carousel ${className}`}>
        <div class="slider">
          <div class="slide-track">
            <h4 class="slide">
              Think beyond EV Charging! Think VerdeMobility!
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <desc></desc>
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
              </svg>{" "}
              Think beyond EV Charging! Think VerdeMobility!
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <desc></desc>
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
              </svg>{" "}
              Think beyond EV Charging! Think VerdeMobility!
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <desc></desc>
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
              </svg>{" "}
              Think beyond EV Charging! Think VerdeMobility!
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <desc></desc>
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
              </svg>{" "}
              Think beyond EV Charging! Think VerdeMobility!{" "}
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke-linecap="round"
                stroke-linejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <desc></desc>
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
              </svg>{" "}
              Think beyond EV Charging! Think VerdeMobility!
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

const VerdemobilityBranding = () => {
  return (
    <div className="portfolio__container">
      <PortfolioHeading
        heading="Verdemobility Branding"
        subHeading="Branding"
        // info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id"
      />

      <div className="portfolio__content reduce__top">
        <Images image={blade_2} fullwidth />

        <div className="verde-hero verde-bgp flex-container -mt-1">
          <h4>
            The true EV charging experience awaits you! With VerdeMobility,
            choose a charging ecosystem that integrates you from the EV charging
            station to the grid, and beyond!
          </h4>
        </div>
        <Images image={blade_3} fullwidth className="-mt-1" />
        <Images image={blade_4} fullwidth className="-mt-1" />
        <Images image={blade_5} fullwidth className="blade__margin" />
        <Images image={blade_6} fullwidth className="blade__margin" />
        <div className="verde-design-wrapper">
          <div className="verde-bgs flex-container ">
            <h3>
              We're an intentionally flexible studio - designed to scale with
              the needs of our clients.
            </h3>
          </div>
          <div className="verde-shapes verde-bgpl flex-container">
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <desc></desc>
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
            </svg>
            <h4>Limitless</h4>
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <desc></desc>
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="12" cy="12" r="9"></circle>
            </svg>
            <h4>Integrity & Stability</h4>
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <desc></desc>
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <rect x="4" y="4" width="16" height="16" rx="2"></rect>
            </svg>
            <h4>Momentum</h4>
          </div>
        </div>
        <Images image={blade_7} className="mt-1" />
        <Images image={blade_8} className="top__margin" />
        <Images image={blade_9} className="top__margin" />

        <InfiniteCarousel className="blade__margin" />

        <div className="verde-bgs blade__padding blade__margin">
          <Images image={blade_10} className="" />
          <Images image={blade_11} className="top__margin" />
          <Images image={blade_12} className="top__margin" />
        </div>

        <Images image={blade_13} className="-mt-1" fullwidth />
        <Images image={blade_14} className="-mt-1" fullwidth />
        {/* verde animations */}
        <Images image={blade_15} className="-mt-1" fullwidth />

        {/* verde slider */}
        <div className="verde__slider blade__padding -mt-1">
          <Carousel />
        </div>

        <div className="blade__padding">
          <Images image={blade_16} className="-mt-1" />
          <Images image={blade_17} className="-mt-1" />
          <Images image={blade_18} className="-mt-1 top__margin" />
          <Images image={blade_19} className="-mt-1 top__margin" />
        </div>
        <Images image={blade_20} className="" fullwidth />
        <InfiniteCarousel className="-mt-1" />
        <Images image={blade_21} fullwidth />
        {/* <Images image={blade_22} fullwidth /> */}

        <PrevAndNextWork
          image1={prevImg}
          image2={nextImg}
          next="/work/informatic-press"
          prev="/work/itat"
        />
        <Footer />
      </div>
    </div>
  );
};
export default VerdemobilityBranding;
