import React from "react";

//components
import PortfolioHeading from "../../Components/PortfolioHeading";
import PrevAndNextWork from "../../Components/PrevAndNextWork";
import Footer from "../../Components/Footer";
// import FlickingCarousel from "../Components/FlickingCarousel";
// import CardsNums from "../Components/Atoms/CardsNums";
// import Heading from "../Components/Atoms/Heading";
// import CardsImage from "../Components/Atoms/CardsImage";
import Images from "../../Components/Atoms/Images";
// import WebsiteGlimpse from "../Components/Atoms/WebsiteGlimpse";

//images
// import vijay from "../assets/images/VCH.jpg";
// import encompass from "../assets/images/encompass.jpg";

import blade_1 from "../../assets/evreBrandingAssets/hero.jpg";
// import blade_2 from "../../assets/evreBrandingAssets/hero.jpg";
import blade_3 from "../../assets/evreBrandingAssets/evre-bg-top.jpg";
import blade_4 from "../../assets/evreBrandingAssets/think-research.jpg";
import blade_5 from "../../assets/evreBrandingAssets/branding.jpg";
import blade_6 from "../../assets/evreBrandingAssets/building-indias-post-1.jpg";
import blade_7 from "../../assets/evreBrandingAssets/building-indias-post-2.jpg";
import blade_8 from "../../assets/evreBrandingAssets/theme-design.jpg";
import blade_9 from "../../assets/evreBrandingAssets/introduction-post.jpg";
import blade_10 from "../../assets/evreBrandingAssets/evre-branding-typography-1.jpg";
import blade_11 from "../../assets/evreBrandingAssets/evre-branding-typography-2.jpg";
import blade_12 from "../../assets/evreBrandingAssets/evre-branding-typography-3.jpg";
import blade_13 from "../../assets/evreBrandingAssets/social-media.jpg";
import blade_14 from "../../assets/evreBrandingAssets/evre-posts-showcase.jpg";
import blade_15 from "../../assets/evreBrandingAssets/evre-posts-mobile-mockup.png";
import blade_16 from "../../assets/evreBrandingAssets/creative-people.png";
import blade_17 from "../../assets/evreBrandingAssets/grid-display.png";
import blade_18 from "../../assets/evreBrandingAssets/mobile-screens.png";
import blade_19 from "../../assets/evreBrandingAssets/evre-brochure.jpg";

// slider
import slide1 from "../../assets/evreBrandingAssets/slide/slide1.svg";

//Video
// import Evre_video from "../assets/evreAssets/Evre_video.mp4";
// import bg_triangle from "../assets/evreAssets/bg_triangle.svg";

// PrevAndNextWork Images
import ima from "../../assets/images/Ima.jpg";
import sleek from "../../assets/images/sleek_new.jpg";

//data

//logo
// import a1 from "../assets/evreAssets/logos/a1.svg";
// import a2 from "../assets/evreAssets/logos/a2.svg";
// import a3 from "../assets/evreAssets/logos/a3.svg";
// import a4 from "../assets/evreAssets/logos/a4.svg";
import VerticalCarousel from "./VerticalCarousel";
const EvreBranding = () => {
  return (
    <div className="portfolio__container">
      <PortfolioHeading
        heading="EVRE Branding"
        subHeading="Branding"
        info="EVRE is a turnkey EV charging product and solutions manufacturer and provider. With a strong network of 550+ EV charging stations across India and a handful of brand partnerships, EVRE approached Blacksof to give a much-required throttle to its brand presence. "
        infoSecondary="Blacksof created its social media theme along with strategizing its monthly calendars to deliver platform-specific communication to its diverse range of B2C and B2B, both types of business verticals and customers. 
        "
      />

      <div className="portfolio__content">
        <div className="evreBrandingWrapper">
          <Images image={blade_1} fullwidth />
          {/* <Images image={blade_2} fullwidth /> */}
          <Images image={blade_3} fullwidth />
          <Images image={blade_4} className="top__margin top__padding" />
          <Images image={blade_5} className="top__margin" />

          <div className="flex-sm simple__width top__margin">
            <Images image={blade_6} />
            <Images image={blade_7} />
          </div>

          <Images image={blade_8} className="top__margin" />
          <Images image={blade_9} className="top__margin" />
          <Images image={blade_10} className="top__margin" />
          <Images image={blade_11} className="top__margin" />
          <Images image={blade_12} className="top__margin" />
          <Images image={blade_13} className="blade__margin" fullwidth />
          <Images image={blade_14} className="top__margin" fullwidth />

          {/* <div className="evre__branding__vertical__carousel__wrapper">
          <div className="heading">
            <h2>
              Build for <br /> tomorrow
            </h2>
          </div>
          <div className="slider">
            <VerticalCarousel slide1={slide1} slide2={slide1} />
          </div>
        </div> */}

          <Images image={blade_15} className="top__margin max-w-lg" />
          <Images image={blade_16} className="top__margin" fullwidth />

          <div className="grid-layout blade__margin">
            <Images image={blade_17} className="max-w-sm" fullwidth />
          </div>

          <Images image={blade_18} className="blade__margin" />
          <Images image={blade_19} className="blade__margin" fullwidth />
        </div>

        <PrevAndNextWork
          prev="/work/vijay-chat-house"
          next="/work/encompass"
          image1={ima}
          image2={sleek}
        />
        <Footer />
      </div>
    </div>
  );
};

export default EvreBranding;
