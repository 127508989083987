import React from "react";

//components
import PortfolioHeading from "../Components/PortfolioHeading";
import PrevAndNextWork from "../Components/PrevAndNextWork";

//  assets import
import banner from "../assets/okasAssets/banner.jpg";
import banner_1 from "../assets/okasAssets/banner_1.jpg";
import allignment from "../assets/okasAssets/allignment.jpg";
import logoDesign from "../assets/okasAssets/logoDesign.jpg";
import logoOptions from "../assets/okasAssets/logoOptions.jpg";
import colors from "../assets/okasAssets/colors.jpg";
import typography from "../assets/okasAssets/typography.jpg";
import stationaryProduct from "../assets/okasAssets/stationaryProduct.jpg";
import designSample_1 from "../assets/okasAssets/designSample_1.jpg";
import designSample_2 from "../assets/okasAssets/designSample_2.jpg";
import productOverview from "../assets/okasAssets/productOverview.jpg";
import brandingLibrary from "../assets/okasAssets/brandingLibrary.jpg";
import okasProperty from "../assets/okasAssets/okasProperty.jpg";
import designSample_3 from "../assets/okasAssets/designSample_3.jpg";
import billboardDesign from "../assets/okasAssets/billboardDesign.jpg";

//  atoms
import Images from "../Components/Atoms/Images";
import CardsImage from "../Components/Atoms/CardsImage";
import PageHeading from "../Components/PageHeading";

//images
import vijay from "../assets/images/VCH.jpg";
import encompass from "../assets/images/encompass.jpg";
import Footer from "../Components/Footer";

const OKAS = () => {
  return (
    <div className="portfolio__container">
      <PortfolioHeading
        heading="OKAS"
        subHeading="UI / UX"
        info="OKAS is an upcoming Australia-based real-estate brand that wanted to present itself as a credible and 
        trustworthy player in the industry. 
        "
        infoSecondary="OKAS partnered with Blacksof to establish its brand identity and build a digital presence.
        We designed their logo and website to launch them into the Australian real estate market as a modern and bespoke 
        real estate solution provider."
      />

      <div className="portfolio__content">
        <Images image={banner} className="blade__margin " />
        <Images image={banner_1} className="mt-1" />
        <Images image={allignment} className="mt-1" />
        <Images image={logoDesign} className="mt-1" />
        <Images image={logoOptions} className="mt-1" />
        <Images image={colors} className="mt-1" />
        <Images image={typography} className="mt-1" />
        <Images image={stationaryProduct} className="mt-1" />
        <Images image={designSample_1} className="mt-1" />
        <Images image={designSample_2} className="mt-1" />
        <Images image={productOverview} className="mt-1" />
        <Images image={brandingLibrary} className="mt-1" />
        <Images image={okasProperty} className="mt-1" />
        <Images image={designSample_3} className="mt-1" />
        <Images image={billboardDesign} className="mt-1" />

        {/* <Images className="thanks__for__watching__icon" image={smile_icon} /> */}
        {/* <h3 className="top__margin dn99_thanks_for">Thanks for watching</h3> */}

        <PrevAndNextWork
          prev="/work/vijay-chat-house"
          next="/work/encompass"
          image1={vijay}
          image2={encompass}
        />
        <Footer />
      </div>
    </div>
  );
};
export default OKAS;
