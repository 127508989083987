import { React, useEffect } from "react";
import ReactPlayer from "react-player";
import Aos from "aos";
import "aos/dist/aos.css";

import vijay from "../assets/images/VCH.jpg";
import encompass from "../assets/images/encompass.jpg";

import PrevAndNextWork from "../Components/PrevAndNextWork";
import Footer from "../Components/Footer";
//Components
import PortfolioHeading from "../Components/PortfolioHeading";
import Images from "../Components/Atoms/Images";
import Banner from "../assets/rudo Assets/Design.png";
import Multiple from "../assets/rudo Assets/Frame 2346.png";
import Saving from "../assets/rudo Assets/Frame 2304.png";
import Logo from "../assets/rudo Assets/Frame 2305.png";
import Rudowealth from "../assets/rudo Assets/Frame 2306.png";
import Dots from "../assets/rudo Assets/Frame 4.png";
import logotwo from "../assets/rudo Assets/Frame8.png";
import RudoBranding from "../assets/rudo Assets/rudo_icon.svg";
import RudoBranding2 from "../assets/rudo Assets/Frame.svg";
import Typo from "../assets/rudo Assets/image.png";
import TextAa from "../assets/rudo Assets/image (1).png";
import Rudologo from "../assets/rudo Assets/Icon_white.svg";
import Rudologo2 from "../assets/rudo Assets/redIcon.svg";
import Rudologo1 from "../assets/rudo Assets/Component 8.svg";
import Multicolor from "../assets/rudo Assets/multicolor.png";
import Assets_img from "../assets/rudo Assets/Frame 2343.png";
import Assets_one from "../assets/rudo Assets/Frame 2339.png";
import Assets_two from "../assets/rudo Assets/Frame 2340.png";
import Assets_three from "../assets/rudo Assets/Frame 2341.png";
import Assets_fourth from "../assets/rudo Assets/Frame 2342.png";
import UX from "../assets/rudo Assets/ux.png";
import Chart from "../assets/rudo Assets/image (2).png";
import Star from "../assets/rudo Assets/star.png";
import Workprocess from "../assets/rudo Assets/workprocess.png";
import FinancialTest from "../assets/rudo Assets/Frame 2390.png";
import Problem from "../assets/rudo Assets/problem.png";
import Instruction from "../assets/rudo Assets/Frame 2358.png";
import Onboarding from "../assets/rudo Assets/FFT_Screens01.png";
import Instruction_two from "../assets/rudo Assets/FFT_Screens02.png";
import Questionnaire from "../assets/rudo Assets/FFT_Screens03.png";
import SignUp from "../assets/rudo Assets/FFT_Screens04.png";
import Result from "../assets/rudo Assets/FFT_Screens05.png";
import Mobile from "../assets/rudo Assets/Mobile Responsive_iPhone 13.png";
import Mobile_one from "../assets/rudo Assets/FFT_Mobile Responsive01.png";
import Mobile_two from "../assets/rudo Assets/FFT_Mobile Responsive02.png";
import Mobile_three from "../assets/rudo Assets/FFT_Mobile Responsive03.png";
import Video from "../assets/rudo Assets/website_video.mp4";
import Fit from "../assets/rudo Assets/Socialmedia_gif.png";
import Until from "../assets/rudo Assets/Socialmedia_all.png";
import Pencil from "../assets/rudo Assets/Mockups01.png";
import Bag from "../assets/rudo Assets/Mockups02.png";
import Tshirt from "../assets/rudo Assets/Mockups03.png";
import Bull from "../assets/rudo Assets/Bull.png";

const Rudo = () => {
  useEffect(function () {
    Aos.init({ duration: 700 });
  }, []);
  return (
    <div className="portfolio__container " data-aos="fade-up">
      <PortfolioHeading
        heading="RuDo Wealth"
        subHeading="UI/UX"
        info="RuDo Wealth is a UAE-based FinTech start-up that provides passive investment solutions, specifically, to GenZ and Millennials to ease out their journey towards financial fitness. 
        "
        infoSecondary="RuDo Wealth partnered with Blacksof to establish a playful yet credible brand identity that could vibe with its target audience. They wanted to simplify the complicated concepts of saving and investing for the youth of UAE. Blacksof created a detailed brand theme and leveraged it to create their Social Media presence along with working on their website UI/UX and development. 
        "
      />
      <div className="portfolio__content">
        <Images image={Banner} fullwidth alt="img" data-aos="fade-up" />
        <Images image={Multiple} fullwidth alt="img" data-aos="fade-up" />
        <Images image={Saving} fullwidth alt="img" data-aos="fade-up" />
        <section className="forms_container ">
          <div className="logo_design ul_rudo " data-aos="fade-up">
            <ul>
              <li>RUDO</li>
              <li>
                <b>BRANDING</b>
              </li>
              <li>PROCESS</li>
            </ul>
          </div>
          <div className="logo_img_wrapper">
            <img
              className="logo_img "
              src={Logo}
              alt="img"
              data-aos="zoom-in-up"
            />
          </div>
        </section>
        <Images image={Rudowealth} fullwidth alt="img" />
        <Images image={Dots} fullwidth alt="img" />
        <section className="concept_container ">
          <div className="logo_design ul_rudo " data-aos="fade-up">
            <ul>
              <li>RUDO</li>
              <li>
                <b>BRANDING</b>
              </li>
              <li>CONCEPT</li>
            </ul>
          </div>
          <div className="logo_img_wrapper">
            <img
              className="logo_two_img "
              src={logotwo}
              alt="img"
              data-aos="zoom-in-up"
            />
          </div>
        </section>
        <Images image={Bull} fullwidth alt="img" />
        <section className="main_design">
          <div className="table_wrapper ul_rudo" data-aos="fade-up">
            <ul>
              <li>RUDO</li>
              <li>
                <b>BRANDING</b>
              </li>
              <li>TYPOGRAPHY</li>
            </ul>
          </div>
          <div className="animation">
            <div className="rudo_design" data-aos="fade-up">
              <img className="rudo_branding " src={RudoBranding} alt="img" />
            </div>
            <div className="rudowealth_design " data-aos="fade-up">
              <img
                className="rudo_branding_two "
                src={RudoBranding2}
                alt="img"
              />
            </div>
          </div>
          <div className="typo_design">
            <div className="typo_img" data-aos="fade-up">
              <img className="typo_img_wrapper" src={Typo} alt="img" />
            </div>
            <div className="type_para" data-aos="fade-up">
              <h5 className="font_epilogue_regural">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id.
              </h5>
            </div>
          </div>
          <div className="circle_container">
            <div className="epilogue">
              <h2 font_epilogue_regural data-aos="zoom-in-up">
                *Epilogue
              </h2>
            </div>
            <div className="background_color ">
              <div className="logo">
                <img className="logo_container" src={Rudologo} alt="img" />
              </div>
              <img
                className="circle "
                src={Rudologo1}
                alt="img"
                data-aos="fade-up"
              />
            </div>
          </div>
          <Images image={TextAa} alt="img" />
          <div className="table_wrapper ul_rudo " data-aos="fade-up">
            <ul>
              <li>RUDO</li>
              <li>
                <b>BRANDING</b>
              </li>
              <li>COLOUR</li>
            </ul>
          </div>
          <div className="color" data-aos="fade-up">
            <h1 className="illustration font_epilogue">COLOUR</h1>
            <h1 className="pallate font_epilogue">PALLATE</h1>
          </div>
          <div className="multicolor" data-aos="fade-up">
            <Images image={Multicolor} alt="img" />
          </div>
          <div className="table_wrapper ul_rudo" data-aos="fade-up">
            <ul>
              <li>RUDO</li>
              <li>
                <b>BRANDING</b>
              </li>
              <li>ILLUSTRATION</li>
            </ul>
          </div>
          <div className="color" data-aos="fade-up">
            <h1 className="illustration font_epilogue">CUSTOM</h1>
            <h1
              className="illustration font_epilogue
          "
            >
              ILLUSTRATION
            </h1>
          </div>
          <div className="multicolor">
            <Images image={Assets_img} alt="img" />
          </div>
          <div className="rudo_card" data-aos="fade-up">
            <div className="rudo_one">
              <Images image={Assets_one} alt="img" />
            </div>
            <div className="rudo_two">
              <Images image={Assets_two} alt="img" />
            </div>
          </div>
          <div className="rudo_card" data-aos="fade-up">
            <div className="rudo_one">
              <Images image={Assets_three} alt="img" />
            </div>
            <div className="rudo_two">
              <Images image={Assets_fourth} alt="img" />
            </div>
          </div>
          <div className="table_wrapper ul_rudo" data-aos="fade-up">
            <ul>
              <li>RUDO</li>
              <li>
                <b>WEB DESIGN</b>
              </li>
              <li>UX</li>
            </ul>
          </div>
          <div className="webdesign" data-aos="fade-up">
            <h2 className="font_epilogue_regural">
              This <strong> projects </strong> brings
            </h2>
            <h2 className="font_epilogue_regural">
              a <strong>peace of mind </strong> by helping to gain
            </h2>
            <h2 className="font_epilogue_regural">
              <strong>financial stability </strong>
            </h2>
          </div>
          <div className="multicolor" data-aos="fade-up">
            <Images image={UX} alt="img" />
          </div>
          <div className="multicolor" data-aos="fade-up">
            <Images image={Chart} alt="img" />
          </div>
          <div className="star">
            <div className="star_text" data-aos="fade-up">
              <h3 className="font_epilogue_regural">
                It may seem that the <strong>conclusion</strong> of the study
                are quite obvious, but <strong>problems</strong> always arise in
                the
                <strong> implementation process</strong>, when it is necessary
                to maintain a <strong> balance </strong>between
                <strong> the users and business goals</strong>
              </h3>
            </div>
            <div className="star_img" data-aos="fade-up">
              <Images image={Star} alt="img" />
            </div>
          </div>
        </section>
        <div className="workprocess" data-aos="fade-up">
          <Images image={Workprocess} fullwidth alt="img" />
        </div>
        <section className="main_design">
          <div className="Vedio_container" data-aos="fade-up">
            <article>
              <h4 className="text_color font_epilogue_regural">
                RuDO Web design.
                <strong className="RUDO_TEXT">RuDO Wealth </strong>. Web design
                .<strong className="RUDO_TEXT">RuDO Wealth </strong>. Web
                design. Web design.
                <strong className="RUDO_TEXT">RuDO Wealth </strong>.
              </h4>
            </article>
            <div className="react_player">
              <ReactPlayer
                url={Video}
                loop
                pip
                playing
                muted
                playsinline
                width="100vw"
                height="100%"
              />
            </div>
          </div>
        </section>
        <section className="main_design ">
          <div className="table_wrapper ul_rudo" data-aos="fade-up">
            <ul>
              <li>RUDO</li>
              <li>
                <b>QUESTIONER</b>
              </li>
              <li>#FINANCIAL </li>
            </ul>
          </div>
          <div className="Financial_wrapper" data-aos="fade-up">
            <div className="blue_container">
              <h2 className="know_container font_epilogue_regural">
                #Financial Wellness Test
              </h2>
              <br></br>
              <h2 className="know_text  font_epilogue_regural">
                Know what your financial fitness is !
              </h2>
            </div>
            <div className="Financial_img">
              <Images image={FinancialTest} fullwidth alt="img" />
            </div>
          </div>
        </section>
        <section className="main_design ">
          <div className="Financial_wrapper">
            <div className="problem" data-aos="fade-up">
              <h3 className="font_epilogue_regural">
                <strong>Problem</strong>
              </h3>
              <h5 className="font_epilogue_regural">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </h5>
            </div>
            <div className="problem" data-aos="fade-up">
              <h3 className="font_epilogue_regural">
                <strong>Solution</strong>
              </h3>
              <h5 className="font_epilogue_regural">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </h5>
            </div>
          </div>
          <Images image={Problem} fullwidth alt="img" data-aos="fade-up" />
        </section>
        <section className="main_design ">
          <div className=" instruction_container" data-aos="fade-up">
            <Images image={Instruction} alt="img" />
          </div>
          <div className="onboarding_wrapper " data-aos="fade-up">
            <div className="dots">1</div>
            <h3 className="font_epilogue_regural">Onboarding</h3>
            <h5 className="font_epilogue_regural">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
            </h5>
            <Images image={Onboarding} alt="img" data-aos="fade-up" />
          </div>
        </section>
        <section className="main_design ">
          <div className="Financial_wrapper " data-aos="fade-up">
            <div className="instruction_wrapper ">
              <div className="dots">1</div>
              <h3 className="font_epilogue_regural">Instruction</h3>
              <h5 className="font_epilogue_regural">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
              </h5>
            </div>
            <Images image={Instruction_two} alt="img" />
          </div>
          <div className="onboarding_wrapper " data-aos="fade-up">
            <div className="dots">1️</div>
            <h3 className="font_epilogue_regural">Questionnaire</h3>
            <h5 className="font_epilogue_regural">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
            </h5>
            <div className="questionnaire ">
              <Images image={Questionnaire} fullwidth alt="img" />
            </div>
          </div>

          <div className="Financial_wrapper" data-aos="fade-up">
            <Images image={SignUp} alt="img" />
            <div className="instruction_wrapper" data-aos="fade-up">
              <div className="dots">1</div>
              <h3 className="font_epilogue_regural">SignUp</h3>
              <h5 className="font_epilogue_regural">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
              </h5>
            </div>
          </div>
          <div className="onboarding_wrapper " data-aos="fade-up">
            <div className="dots">1</div>
            <h3 className="font_epilogue_regural">Result</h3>
            <h5 className="font_epilogue_regural">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy
            </h5>
            <Images image={Result} alt="img" />
          </div>
          <div className="mobileresponsive" data-aos="fade-up">
            <div className="mobile_text">
              <h3 className="font_epilogue_regural">Mobile Responsive</h3>
            </div>
            <div className="mobile">
              <img className="mobile_img" src={Mobile} alt="img" />
            </div>
          </div>
        </section>
        <section className="forms_container ">
          <div className="forms_wrapper" data-aos="fade-up">
            <div className="mobile_one ">
              <img className="mobile_one_img" src={Mobile_one} alt="img" />
              <h4 className="font_epilogue_regural">Onboarding</h4>
            </div>
            <div className="mobile_one">
              <img className="mobile_one_img" src={Mobile_three} alt="img" />
              <h4>Forms</h4>
            </div>
            <div className="mobile_one">
              <img className="mobile_one_img" src={Mobile_two} alt="img" />
              <h4 className="font_epilogue_regural">Result</h4>
            </div>
          </div>
        </section>
        <section className="main_design">
          <div className="table_wrapper ul_rudo" data-aos="fade-up">
            <ul>
              <li>RUDO</li>
              <li>
                <b>BRANDING</b>
              </li>
              <li>SOCIAL MEDIA</li>
            </ul>
          </div>
          <Images image={Fit} alt="img" />
          <img
            className="untill_wrapper"
            src={Until}
            fullwidth
            alt="img"
            data-aos="fade-up"
          />
          <img
            className="untill_wrapper"
            src={Pencil}
            fullwidth
            alt="img"
            data-aos="fade-up"
          />
          <div className="bag_container" data-aos="fade-up">
            <Images image={Bag} alt="img" />
            <Images image={Tshirt} alt="img" />
          </div>
          <div className="thanks_container ">
            <img
              className="thanks_img"
              src={Rudologo2}
              alt="img"
              data-aos="fade-up"
            />
            <h2 className="font_epilogue_regural">Thank you for Watching!</h2>
          </div>
        </section>

        <PrevAndNextWork
          prev="/work/brebel"
          next="/work/encompass"
          image1={vijay}
          image2={encompass}
        />
        <Footer />
      </div>
    </div>
  );
};

export default Rudo;
