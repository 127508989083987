import { React, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import PortfolioHeading from "../Components/PortfolioHeading";
import PrevAndNextWork from "../Components/PrevAndNextWork";
import Footer from "../Components/Footer";
import vijay from "../assets/images/VCH.jpg";
import encompass from "../assets/images/encompass.jpg";
import Images from "../Components/Atoms/Images";
//Assets;
import Banner from "../assets/swanAssets/hero.png";
import Goal from "../assets/swanAssets/website concept.png";
import Pain from "../assets/swanAssets/Paingainex.png";
import Swan_font from "../assets/swanAssets/fontSize.png";
import Black_Orange_color from "../assets/swanAssets/color_black_orange.png";
import ArrowBox from "../assets/swanAssets/arrowbox.png";
import Gridelement from "../assets/swanAssets/grid_Element.png";
import Laptop from "../assets/swanAssets/laptop.png";
import Home from "../assets/swanAssets/home.png";
import Testimonial from "../assets/swanAssets/testimonial.png";
import Screen_responsive from "../assets/swanAssets/Screen.png";
import Three_mobile from "../assets/swanAssets/three_mobile.png";
import Multi_phone from "../assets/swanAssets/multi_phone.png";
import Cinema from "../assets/swanAssets/cinema.png";
const Swan = () => {
  useEffect(function () {
    Aos.init({ duration: 700 });
  }, []);
  return (
    <div className="portfolio__container " data-aos="fade-up">
      <PortfolioHeading
        heading="SWAN Finance"
        subHeading="UI/UX"
        // info="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      />

      <div className="portfolio__content reduce__top">
        <Images
          className="swan_banner"
          image={Banner}
          alt="img"
          data-aos="zoom-in-up"
        />
        <section className="swan_container">
          <div className="goal_wrapper " data-aos="fade-up">
            <h2 className="fontAccess">
              Our goal is to create a fresh<br></br> finance website concept
            </h2>
            <br></br>
            <br></br>
            <h4 className="fontAccess">
              To achieve this goal, we set a number of tasks, which we <br></br>
              were able to accomplish in four months.
            </h4>
            <img className="goal_img_wrapper " src={Goal} alt="img" />
          </div>
        </section>
        <section className="product_container">
          <div className="goal_wrapper " data-aos="fade-up">
            <h2 className="fontAccess">What should the product be?</h2>
            <br></br>
            <br></br>
            <h4 className="fontAccess">
              The next step is to research the user experience of using delivery
              <br></br>
              website to understand their pains, needs and expectations.
            </h4>
            <h4 className="market_wrapper fontAccess">
              Problem interview + Market feedback
            </h4>
            <img className="goal_img_wrapper " src={Pain} alt="img" />
            <h2 className="fontAccess">45+ insights</h2>
          </div>
        </section>
        <Images image={Swan_font} alt="img" />
        <div className="color_wrapper">
          <Images image={Black_Orange_color} alt="img" />
        </div>
        <section className="swan_container">
          <div className="goal_wrapper  " data-aos="fade-up">
            <h2 className="fontAccess">Iconography</h2>
            <br></br>
            <br></br>
            <h4 className="fontAccess">
              A need for an extensive icon set appeared in order to strengthen
              the
              <br></br>
              brand’s visual identity through the product and allow for ituitive
              and <br></br>fast interaction with the interface.
            </h4>
            <div className="iconography_wrapper">
              <img className="width" src={ArrowBox} alt="img" />
              <img className="width" src={Gridelement} alt="img" />
            </div>
          </div>
        </section>
        <div className="color_wrapper">
          <Images image={Laptop} alt="img" />
        </div>
        <section className="homepage_wrapper">
          <div className="testimonial_wrapper" data-aos="fade-up">
            <div className="home_page ">
              <h4 className="fontAccess">Home page</h4>
              <br></br>
              <img className="testimonial" src={Home} alt="img" />
            </div>
            <div>
              <img className="testimonial" src={Testimonial} alt="img" />
            </div>
          </div>
        </section>
        <section className="product_container">
          <div className="goal_wrapper" data-aos="fade-up">
            <h2 className="fontAccess">Screen Responsive</h2>
            <img
              className="goal_img_wrapper "
              src={Screen_responsive}
              alt="img"
            />
          </div>
        </section>
        <div className="color_wrapper">
          <Images image={Three_mobile} alt="img" />
        </div>
        <div className="color_wrapper">
          <Images image={Multi_phone} alt="img" />
        </div>
        <div className="color_wrapper">
          <Images image={Cinema} alt="img" />
        </div>
        <section className="homepage_wrapper">
          <div className="Thanks_swan" data-aos="fade-up">
            <h2 className="fontAccess " data-aos="zoom-in-up">
              THANKS
            </h2>
          </div>
        </section>

        <PrevAndNextWork
          prev="/work/rudo"
          next="/work/encompass"
          image1={vijay}
          image2={encompass}
        />
        <Footer />
      </div>
    </div>
  );
};

export default Swan;
