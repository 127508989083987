import React from "react";

//components
import PortfolioHeading from "../Components/PortfolioHeading";
import PrevAndNextWork from "../Components/PrevAndNextWork";

//  assets import
import quote from "../assets/dn99Assets/quote.png";
import scopeOfWork from "../assets/dn99Assets/scopeOfWork.png";
import logoBrandIdentity from "../assets/dn99Assets/logoBrandIdentity.png";
import color_pallate from "../assets/dn99Assets/color_pallate.png";
import mock_ups from "../assets/dn99Assets/mock_ups.jpg";
import socialMedia from "../assets/dn99Assets/socialMedia.png";
import phone_mockup from "../assets/dn99Assets/phone_mockup.jpg";
import website_preview from "../assets/dn99Assets/website_preview.png";
import website_pages_preview from "../assets/dn99Assets/website_pages_preview.png";
import message from "../assets/dn99Assets/message.png";

//  atoms
import Images from "../Components/Atoms/Images";
import CardsImage from "../Components/Atoms/CardsImage";
import PageHeading from "../Components/PageHeading";

//images
import vijay from "../assets/images/VCH.jpg";
import encompass from "../assets/images/encompass.jpg";
import Footer from "../Components/Footer";

const DN99 = () => {
  return (
    <div className="portfolio__container">
      <PortfolioHeading
        heading="DN99"
        subHeading="UI / UX"
        info="DN99 is a Gujarat-based NGO established by a group of 99 Indians and NRIs inspired 
        by the selfless acts of Dadabhai Naraoji. The NGO aims to help underprivileged children and women access 
        basic amenities like proper meals, education, shelter, etc."
        infoSecondary="DN99 joined hands with Blacksof (a pro bono collab) to create a brand theme along with their Social Media presence and Website. All centered around raising awareness about their work and attracting volunteers from across India."
      />

      <div className="portfolio__content">
        <Images image={quote} className="blade__margin " />
        <Images image={scopeOfWork} className="blade__margin" />
        <Images image={logoBrandIdentity} className="blade__margin" />
        <Images image={color_pallate} className="blade__margin" />

        <PageHeading heading="Advertising" />
        <Images image={mock_ups} className="top__margin" />

        <PageHeading heading="Social Media" />
        <Images image={socialMedia} className="top__margin" />
        <Images image={phone_mockup} className="blade__margin" />

        <PageHeading heading="Website" />
        <Images image={website_preview} className="top__margin" />
        <Images image={website_pages_preview} className="blade__margin" />
        <Images image={message} className="blade__margin" />

        {/* <Images className="thanks__for__watching__icon" image={smile_icon} /> */}
        {/* <h3 className="top__margin dn99_thanks_for ">Thanks for watching</h3> */}

        <PrevAndNextWork
          prev="/work/vijay-chat-house"
          next="/work/encompass"
          image1={vijay}
          image2={encompass}
        />
        <Footer />
      </div>
    </div>
  );
};
export default DN99;
