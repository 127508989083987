import React, { useEffect, useState } from "react";
import Images from "../Components/Atoms/Images";
import PortfolioHeading from "../Components/PortfolioHeading";
import CardsImage from "../Components/Atoms/CardsImage";
import Heading from "../Components/Atoms/Heading";

// Assests
import firstBlade_cow_image from "../assets/gavyamAssets/firstBlade_cow_image.png";
import cheer_up from "../assets/gavyamAssets/cheer_up.png";
import project__timeline from "../assets/gavyamAssets/project__timeline.png";
import Colors_typography from "../assets/gavyamAssets/Colors_typography.png";
import UserFlow__fidelity from "../assets/gavyamAssets/UserFlow__fidelity.png";
import Mobile__app__1 from "../assets/gavyamAssets/Mobile__app__1.png";
import Mobile__app__2 from "../assets/gavyamAssets/Mobile__app__2.png";
import flower__design__outlined from "../assets/gavyamAssets/flower__design__outlined.svg";
import Homepage__view__mobile from "../assets/gavyamAssets/Homepage__view__mobile.png";
import components__used from "../assets/gavyamAssets/components__used.svg";
import few__more__screens from "../assets/gavyamAssets/few__more__screens.png";
import website__first__section from "../assets/gavyamAssets/website__first__section.png";
import aboutus from "../assets/gavyamAssets/aboutus.png";
import resource from "../assets/gavyamAssets/resource.png";
import tab__view from "../assets/gavyamAssets/tab__view.png";
import track__for__project__goal from "../assets/gavyamAssets/track__for__project__goal.svg";

import stationary_01 from "../assets/gavyamAssets/stationary_01.png";
import stationary_02 from "../assets/gavyamAssets/stationary_02.png";
import stationary_03 from "../assets/gavyamAssets/stationary_03.png";
import stationary_04 from "../assets/gavyamAssets/stationary_04.png";
import stationary_05 from "../assets/gavyamAssets/stationary_05.png";
import stationary_06 from "../assets/gavyamAssets/stationary_06.png";
import stationary_07 from "../assets/gavyamAssets/stationary_07.png";

import banner__cow from "../assets/gavyamAssets/banner__cow.jpg";
import Thanks__for__wotching from "../assets/gavyamAssets/Thanks__for__wotching.svg";
import PagerHeading from "../Components/Atoms/PagerHeading";
import PrevAndNextWork from "../Components/PrevAndNextWork";

// PrevAndNextWork Images
import ima from "../assets/images/Ima.jpg";
import sleek from "../assets/images/sleek_new.jpg";
import Footer from "../Components/Footer";

const ourRoles = [
  {
    first: "E",
    uls: "mpathize",
    para: `To communicate with the end users (AI workers, cattle breeders, and milk unions), we analyzed insights about 
    users’ needs and concerns. We also explored the shortcomings and benefits of the conventional 
    AI process along with the already existing products in the market.  
    `,
    image: cheer_up,
    fontcolor: "#B55219",
  },
  {
    first: "D",
    uls: "efine",
    para: "The key challenge was to help users seamlessly transition from conventional methods of cattle management and breeding to digital practices. And presenting Gavyam as the next-gen solution in the animal husbandry industry was the first objective. ",
    image: cheer_up,
    fontcolor: "#C9A100",
  },
  {
    first: "I",
    uls: "deate",
    para: "A minimalistic approach and an easy-to-follow flow were the strategies for communicating the importance of digitization. The message had to be conveyed via different branding and marketing tools in the online as well as the offline world.  ",
    image: cheer_up,
    fontcolor: "#8D2A00",
  },
  {
    first: "D",
    uls: "esign",
    para: "The process started with a low-fidelity solution and gradually excelled in creating a full-fledged brand identity creation. Moving forward with the valuable feedback from the client and audience, various branding collaterals like packaging designs, manuals, explainer videos, brochure, website, app, etc. were designed.",
    fontcolor: "#32B519",
  },
  {
    first: "T",
    uls: "est",
    para: "The prototype of every design was first displayed to the end-users, where we recorded their viewpoints on every aspect. Following their valuable input, we improvised and created the final designs as per the client’s need. ",
    image: cheer_up,
    fontcolor: "#0099C9",
  },
];

const ProjectGoal = ({
  title,
  para,
  className,
  image,
  image_1,
  bgclr,
  color,
  title_1,
}) => {
  return (
    <div className={className}>
      {image && (
        <div className="half__left">
          <Images image={image} />
        </div>
      )}
      <div
        className="half__left project__goal__right"
        style={{ background: bgclr }}
        data-aos="fade-up"
      >
        {title ? <h4 style={{ color: color }}>{title}</h4> : ""}
        {title_1 ? <h3 style={{ color: color }}>{title_1}</h3> : ""}
        {image_1 ? <Images image={image} /> : ""}
        <p> {para}</p>
      </div>
    </div>
  );
};

const Backgrounds = ({ className, background, height }) => {
  return (
    <div className={`${className} backgrounds__defaults`}>
      <div style={{ height: height, background: background }}></div>
    </div>
  );
};

const AboutPageView = ({ className, image, title, para, position__text }) => {
  return (
    <div className={`${className}`}>
      <Images image={image} fullwidth />

      <div className={`${position__text}`} data-aos="fade-up">
        <h3>{title}</h3>
        <p>{para}</p>
      </div>
    </div>
  );
};

const Tracker = ({ progress }) => {
  return (
    <svg
      id="track__project__goal__image"
      viewBox="0 0 678 2595"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-542.386 -5723.826)">
        <path
          d="M-8838.564-18829c-8.934,74.816,105.866,98.184,101.564,165.068"
          transform="translate(9827 24553)"
          fill="none"
          stroke="#9b9b9b"
          stroke-width="3"
          stroke-dasharray="10"
        />
        <path
          d="M21.927,735.571S190.021,636.3,178.505,401.291,260.1,139.8,319.317,97.018"
          transform="translate(445.342 6975.232) rotate(-86)"
          fill="none"
          stroke="#9b9b9b"
          stroke-width="3"
          stroke-dasharray="10"
        />
        <path
          d="M-240.9,380.563S-263.131,277.886-94.645,206.046,78.88-43.152,78.88-43.152"
          transform="translate(667.144 6399.988) rotate(-155)"
          fill="none"
          stroke="#9b9b9b"
          stroke-width="3"
          stroke-dasharray="10"
        />
        <path
          d="M43.629,455.092C37.663,409.171,32.687,359,164.209,183.677s75.84-302.059,75.84-302.059"
          transform="translate(924.179 7283.719) rotate(30)"
          fill="none"
          stroke="#9b9b9b"
          stroke-width="3"
          stroke-dasharray="10"
        />
      </g>
    </svg>
  );
};

const Gavyam = () => {
  const [pathlength, setPathlength] = useState(0);

  useEffect(() => {}, []);

  return (
    <div className="portfolio__container">
      <PortfolioHeading
        heading="Gavyam"
        subHeading="UI/UX"
        info="Gavyam is the parent company of digiGAI; a brand that offers innovative turn-key solutions for cattle farmers and artificial insemination workers. Building on their vision, Blacksof designed digital assets to help Gavyam Gentec Pvt. Ltd. establish a distinctive product line in the industry. 
        "
      />

      <div className="portfolio__content">
        <div
          className="evre__main__container"
          style={{ background: "#e5e7e2", paddingBottom: "0rem" }}
        >
          <Images image={firstBlade_cow_image} />

          <div className="wrapper" data-aos="fade-up">
            <div className="internal__wrapper">
              <PagerHeading
                className="pagerheading__atom"
                clr="#000"
                bgclr="#fff"
                num="01"
                text="Our Roles"
              />

              <div className="our__roles__wrapper__cards padding__bottom">
                {ourRoles.map((item, index) => {
                  return (
                    <CardsImage
                      className="cards__Image"
                      // image={item.image}
                      uls={item.uls}
                      first={item.first}
                      para={item.para}
                      fontcolor={item.fontcolor}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <Backgrounds
            background="#B55219"
            height="80vh"
            className="position__mid__background"
          />

          <div className="wrapper">
            <div className="internal__wrapper">
              <PagerHeading
                className="pagerheading__atom"
                clr="#000"
                bgclr="#fff"
                num="02"
                text="Project Timeline"
              />

              <div className="our__roles__wrapper__cards top__margin">
                <Images
                  className="project__timeline"
                  image={project__timeline}
                  fullwidth
                />
              </div>
            </div>
          </div>

          <Backgrounds
            background="#1F2D16"
            height="80vh"
            className="position__mid__background"
          />

          <div className="wrapper">
            <div className="internal__wrapper padding__bottom">
              <PagerHeading
                className="pagerheading__atom"
                clr="#000"
                bgclr="#fff"
                num="03"
                text="Project Goals"
              />

              <div className="project__goal__main">
                {/* <div className="track__project__goal" data-aos="fade-up">
                  <img src={track__for__project__goal} className="image track__project__goal__image" />
                  <Tracker />
                </div> */}

                <ProjectGoal
                  className="project__goal__wrapper"
                  // image={cheer_up}
                  bgclr="#fff"
                  title="Grow Awareness 
"
                  para="The prominent focus of the design is to grow awareness around the brand and encourage users to digitize cattle management and breeding with Gavyam.                   "
                  color="#3eb86d"
                />

                <ProjectGoal
                  className="project__goal__wrapper__reverse"
                  // image={cheer_up}
                  title="Easy-to-navigate
                  "
                  bgclr="#fff"
                  para="The app design had to be simple and relatable to the audience hailing from an animal husbandry background. Website design aims to appeal to business decision-makers, therefore a sophisticated and elegant approach was followed there."
                  color="#f8ae5f"
                />

                <ProjectGoal
                  className="project__goal__wrapper"
                  // image={cheer_up}
                  bgclr="#fff"
                  title="Flexibility
                  "
                  para="Gavyam is a booming company that is revolutionizing the dairy industry one product at a time. Therefore, we designed elements and designs that could be customized in the future while keeping the core essence intact. 

                  "
                  color="#f97558"
                />

                <ProjectGoal
                  className="project__goal__wrapper__reverse"
                  // image={cheer_up}
                  title="Lead generation
                  "
                  bgclr="#fff"
                  para="The end goal of the collaterals is to encourage digitalization in animal husbandry and promote the use of digiGAI products amongst relevant users. We achieved this by crafting SEO-friendly design and content."
                  color="#9958eb"
                />

                {/* <ProjectGoal
                  className="project__goal__wrapper"
                  image={cheer_up}
                  bgclr="#fff"
                  title="Convenience"
                  para="Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum "
                  color="#3f66f7"
                /> */}
              </div>
            </div>
          </div>

          <Backgrounds
            background="#B55219"
            height="80vh"
            className="position__mid__background"
          />

          <div className="wrapper">
            <div className="internal__wrapper" data-aos="fade-up">
              <Heading
                Heading="Project Highlights"
                className="general__heading"
              />

              <div className="cards__wrapper">
                <div>
                  <h4 style={{ color: "#b55219" }}> Challenges </h4>
                  <p>
                    Gavyam aimed to penetrate a traditional industry with
                    digitalization. In response to the wave, we worked on
                    establishing the brand and products as a revolutionary
                    solution in the cattle industry.
                  </p>
                </div>
                <div>
                  <h4 style={{ color: "#b55219" }}> Solution </h4>
                  <p>
                    To create an impact in an otherwise conventional market, we
                    chose to use traditional components like mandala art, Gir
                    cow and communicative language in design. The technical
                    aspect of the project was represented using sophisticated
                    geometrical elements and descriptive words.
                  </p>
                </div>
                <div>
                  <h4 style={{ color: "#b55219" }}> Outcomes </h4>
                  <p>
                    The team delivered an actionable and insightful website,
                    interactive application, and a range of branding
                    collaterals. The design work helped position Gavyam as a
                    tech-first innovative brand and penetrate major milk unions
                    and distributors.
                  </p>
                </div>
              </div>

              {/* <Images image={project__timeline} className="problems__image" /> */}
            </div>
          </div>

          {/* LOGO */}
          {/* <div className="logo__wrapper wrapper">
            <h1>LOGO</h1>
          </div> */}

          <div className="wrapper color__wrapper">
            <div className="internal__wrapper ">
              <PagerHeading
                className="pagerheading__atom"
                clr="#fff"
                bgclr="#656b54"
                num="04"
                text="Colors & Typography"
              />

              <Images
                className="project__timeline padding__bottom top__margin"
                fullwidth
                image={Colors_typography}
              />
            </div>
          </div>

          <Backgrounds
            background="#1F2D16"
            height="80vh"
            className="position__mid__background"
          />
          {/* #### User flow & high-fidelity prototype  #### */}

          <div className="wrapper user__flow__wrapper">
            <div className="internal__wrapper">
              <PagerHeading
                className="pagerheading__atom"
                clr="#000"
                bgclr="#e4e5e5"
                num="05"
                text="User flow & high-fidelity prototype"
              />
            </div>

            <Images
              className="project__timeline"
              fullwidth
              image={UserFlow__fidelity}
            />
          </div>

          <Backgrounds
            background="#B55219"
            height="80vh"
            className="position__mid__background"
          />

          {/* #### Mobile App */}
          <div className="wrapper ">
            <div className="internal__wrapper padding__bottom ">
              <PagerHeading
                className="pagerheading__atom"
                clr="#000"
                bgclr="#fff"
                num="06"
                text="Mobile App"
              />

              <ProjectGoal
                className="project__goal__wrapper__reverse mobile__app__flexbox"
                image={Mobile__app__1}
                title="Ease of access
                "
                para="The first touchpoint to create a seamless user experience was to make the ‘Sign in’ process uncomplicated and effortless. 
                "
              />
              <ProjectGoal
                className="project__goal__wrapper mobile__app__flexbox"
                image={Mobile__app__2}
                title="Animal directory 
                "
                para="To set up the devices connected, the app had to factor in a series of technical questions at the very beginning, which was a major hindrance. We conquered it by designing a comprehensive, compelling and interactive UI. "
              />
            </div>
          </div>

          <Backgrounds height="35vh" background="#363f28" />

          <div className="homepage__view__mobile ">
            <div className="wrapper">
              <ProjectGoal
                className="project__goal__wrapper__reverse homepage__heading"
                image={flower__design__outlined}
                title_1="The Gavyam App
                "
                para="The Gavyam app is prominently created to help digiGAI users operate the devices, accumulate cattle data, and connect with experts at the time of need.
                "
              />
              <br />
              <br />
              <br />
            </div>
            <Images
              className="homepage__view__width"
              image={Homepage__view__mobile}
              fullwidth
            />
          </div>

          <div className="components__used__wrapper">
            <div className="components__used__inside wrapper padding__bottom ">
              <div className="internal__wrapper" data-aos="fade-up">
                <h3>Components</h3>
                <br />
                <br />
                <br />
                <Images image={components__used} fullwidth />
              </div>
            </div>
          </div>
          <Images image={few__more__screens} fullwidth />

          {/* ######## Website ######## */}

          <div className="website__wrapper wrapper color__wrapper">
            <div className="internal__wrapper">
              <PagerHeading
                className="pagerheading__atom"
                clr="#fff"
                bgclr="#656b54"
                num="07"
                text="Our Website"
              />
              <div className="top__margin">
                <Images image={website__first__section} />
              </div>
            </div>
          </div>
          <Backgrounds height="35vh" background="#1F2D16" />

          {/* ######## About PAGE */}
          <div className="about__page__wrapper padding__bottom">
            <div className="wrapper">
              <AboutPageView
                className="about__page__view padding__bottom"
                position__text="pos__para__right"
                image={aboutus}
                title="About Page"
                para="The Website’s UI/UX has been carefully crafted with an agri-tech focus in mind. Our team led in sync to deliver a simple but exceptional informational flow throughout with interactive design. 

                "
              />

              {/* <AboutPageView
                className="about__page__view padding__bottom"
                position__text="pos__para__left"
                image={resource}
                title="Resource Page"
                para="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              /> */}
            </div>
          </div>

          <Images className="tab__view__images" fullwidth image={tab__view} />

          <div className="stationary__wrapper stationary__width">
            <PagerHeading
              className="pagerheading__atom"
              clr="#000"
              bgclr="rgb(229, 231, 226)"
              num="08"
              text="Stationary & Merchandising"
            />

            <div className="stationary__grid__wrapper grid__half top__margin">
              <div>
                <Images image={stationary_01} fullwidth />
              </div>
              <div>
                <Images image={stationary_02} fullwidth />
              </div>
              <div>
                <Images image={stationary_03} fullwidth />
              </div>
              <div>
                <Images image={stationary_04} fullwidth />
              </div>
            </div>

            <div className="">
              <Images
                className="grid__margin"
                image={stationary_05}
                fullwidth
              />
              <Images
                className="grid__margin"
                image={stationary_06}
                fullwidth
              />
              <Images
                className="grid__margin"
                image={stationary_07}
                fullwidth
              />
            </div>
          </div>

          {/* ######## Banner Image ######### */}
          <Images className="grid__margin" image={banner__cow} fullwidth />

          {/* <div className="thank__for__watching__wrapper">
            <Images className="" image={Thanks__for__wotching} fullwidth />
            <div className="contents">
              <h2>Thank you for attention!</h2>
            </div>
          </div> */}

          <PrevAndNextWork
            prev="/work/vijay-chat-house"
            next="/work/encompass"
            image1={ima}
            image2={sleek}
          />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Gavyam;
