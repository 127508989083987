import React from "react";
import Images from "../Components/Atoms/Images";
import bgStar from "../assets/brebelBranding/blade2/star.png";
import social from "../assets/brebelBranding/social.png";
import collage from "../assets/brebelBranding/collage.png";
import cards from "../assets/brebelBranding/cards.png";
import billboard from "../assets/brebelBranding/billboard.png";
import adversiting from "../assets/brebelBranding/advertising.png";
import carouselImage from "../assets/brebelBranding/blade10/screens.png";
import PortfolioHeading from "../Components/PortfolioHeading";
import blackCircle from "../assets/brebelBranding/blade7/black_circle.png";
import whiteCircle from "../assets/brebelBranding/blade7/white_circle.png";
import album from "../assets/brebelBranding/album.png";
import textGrid from "../assets/brebelBranding/textGrid.png";
import logoGrid from "../assets/brebelBranding/logoGrid.png";
import cardCollage2 from "../assets/brebelBranding/cardCollage.png";
import bagStyle from "../assets/brebelBranding/bag_style.png";
import thankYou from "../assets/brebelBranding/thank_you.png";
import textFlex from "../assets/brebelBranding/textFlex.png";
import textArt from "../assets/brebelBranding/card_art/text_art.png";
import cardArt from "../assets/brebelBranding/card_art/card_art.png";
import PrevAndNextWork from "../Components/PrevAndNextWork";
import placeholder from "../assets/images/placeholer_image.webp";
import Footer from "../Components/Footer";
export default function BrebelBranding() {
  return (
    <section className="portfolio__container">
      <PortfolioHeading
        heading="Brebel Branding"
        subHeading="Branding"
        info="B.Rebel is an upcoming consumer electronics brand in India. 
        They wanted to penetrate into this highly competitive segment by building a strong brand resonance 
        that unequivocally calls out rebels, no matter the age, creed, sex, or generation."
        infoSecondary="B.Rebel joined forces with Blacksof to establish a unique brand identity that reinforced their mission while being playful and quirky. Blacksof found the brand from scratch, right from its brand mark to an exhaustive brand theme, packaging collaterals, website, social media launch, and performance marketing."
      />
      <div className="portfolio__content">
        <div className="blade1__wrapper"></div>

        <div className="blade2__wrapper">
          <h3>The Problem and The Strategy</h3>
          <p>
            As an electronic brand that faces intense competition, we see the
            main potential threat for B.Rebel, is to be sunked in the sea of
            similar competitors. From the get-go, we know that we need to imbue
            our branding and visual strategy, to create a layer of
            differentiation that makes the brand unique, but at the same time
            feels relevant to it’s core target market. Relevancy, is crucial for
            the task because the principal that owns the brand have emphasized
            their intention to build strong brand efficacy in the heart and
            minds of the consumer.
          </p>
          <img src={bgStar} className="bgImg" alt="brebel is a star" />
        </div>
        <div className="blade3__wrapper"></div>

        <div className="blade4__wrapper ">
          <div className="text__wrapper">
            <h5>MoodBoard</h5>
            <span>
              The design process started by creating the moodboard to find the
              right metaphor and visual rhythm for a future identity, we explore
              hundreds of references. then we carefully grouped and filtered our
              findings to create self-explaining moodboard.
            </span>
          </div>
          <video autoPlay loop muted>
            <source
              src="https://res.cloudinary.com/rakesh-gupta/video/upload/v1665233552/Pexels-Ivan-Samkov-9640804_1_c0myhu.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="blade5__wrappper">
          <Images image={collage} fullwidth />
        </div>
        <div className="blade6__wrapper">
          <div className="text__wrapper">
            <h5>Logotype</h5>
            <span>
              It is a futuristic wordmark logo that embodies the spirit of being
              a rebel. The design looks powerful, different, immovable just like
              a strong headed person. The word ‘B’ puts emphasis on ‘being’
              because it’s easy to say you’re rebel, not easy being one
            </span>
          </div>
        </div>
        <div className="blade7__wrapper">
          <div className="left">
            <Images image={whiteCircle} />
          </div>
          <div className="right">
            <Images image={blackCircle} />
          </div>
        </div>
        <div className="blade8__wrapper ">
          <Images image={logoGrid} fullwidth className="img__wrapper" />
        </div>
        <div className="blade9__wrapper ">
          <div className="text__wrapper">
            <h5>Typography</h5>
          </div>
          <Images image={textGrid} fullwidth />
        </div>

        <div className="blade10__wrapper ">
          <div className="text__wrapper">
            <h5>Color</h5>
          </div>
          <div className="img__wrapper">
            <Images image={carouselImage} />
          </div>
        </div>

        <div className="-mt-1 ribbon__image">
          <Images image={textFlex} fullwidth />
        </div>
        <div className="-mt-1 ">
          <Images image={cards} fullwidth className="brebelBranding__img" />
        </div>
        <div className="-mt-1">
          <Images image={album} fullwidth className="brebelBranding__img" />
        </div>
        <div className="-mt-1 ">
          <Images image={social} fullwidth className="brebelBranding__img" />
        </div>

        <Images image={cardCollage2} className="-mt-1" fullwidth />
        <div className="card_art -mt-1 ">
          <Images image={textArt} />
          <Images image={cardArt} />
        </div>
        <div className="-mt-1">
          <Images image={billboard} className="brebelBranding__img" fullwidth />
        </div>
        <div className="-mt-1">
          <Images
            image={adversiting}
            className="brebelBranding__img"
            fullwidth
          />
        </div>
        <div className="-mt-1">
          <Images image={bagStyle} fullwidth />
        </div>
        <div className="-mt-1">
          <Images image={thankYou} fullwidth />
        </div>
        <PrevAndNextWork
          prev="/work/rudo"
          next="/work/encompass"
          image1={placeholder}
          image2={placeholder}
        />
        <Footer />
      </div>
    </section>
  );
}
