import React from "react";

//components
import PortfolioHeading from "../Components/PortfolioHeading";
import PrevAndNextWork from "../Components/PrevAndNextWork";

//  assets import
import logo from "../assets/varahaAssets/logo-varaha.png";
import logoTitle from "../assets/varahaAssets/logo-title.png";
import logoChakraGreen from "../assets/varahaAssets/logochakra-green.png";
import banner from "../assets/varahaAssets/banner-img.jpg";
import productGrid from "../assets/varahaAssets/product-grid.jpg";
import logoDesign from "../assets/varahaAssets/logo-design-structure.png";
import logoOptions from "../assets/varahaAssets/logo-options.jpg";
import typography from "../assets/varahaAssets/moodboard.jpg";
import fonts_sample from "../assets/varahaAssets/typography.jpg";
import patternslogic from "../assets/varahaAssets/patternslogic.jpg";
import logoview from "../assets/varahaAssets/logoview.jpg";
import brandingbook from "../assets/varahaAssets/brandingbooks.jpg";
import brandingvehicle from "../assets/varahaAssets/brandingvehicle.jpg";
import brandingcards from "../assets/varahaAssets/brandingcards.jpg";
import brandingphoneview from "../assets/varahaAssets/brandingphoneview.jpg";
import brandinglibrary from "../assets/varahaAssets/brandinglibrary.jpg";
import productOverview from "../assets/varahaAssets/laptopview.jpg";
import homepage from "../assets/varahaAssets/homepage.png";
import about from "../assets/varahaAssets/aboutus.png";
import business from "../assets/varahaAssets/business.png";
import partners from "../assets/varahaAssets/partners.jpg";
import quotes from "../assets/varahaAssets/quotes.jpg";
import ideas from "../assets/varahaAssets/ideas.jpg";
import career from "../assets/varahaAssets/career.jpg";
import allpages from "../assets/varahaAssets/allpages.jpg";
import responsiveview from "../assets/varahaAssets/responsiveview.jpg";
import pattern from "../assets/varahaAssets/bg-pattern.png";
import Group from "../assets/varahaAssets/Group.png";

//  atoms
import Images from "../Components/Atoms/Images";
import CardsImage from "../Components/Atoms/CardsImage";
import PageHeading from "../Components/PageHeading";

//images
import vijay from "../assets/images/VCH.jpg";
import encompass from "../assets/images/encompass.jpg";
import Footer from "../Components/Footer";

const Varaha = () => {
  return (
    <div className="portfolio__container">
      <PortfolioHeading
        heading="Varaha"
        subHeading="UI / UX"
        info="Our team’s first gig in the climate tech space.
        Varaha is the world’s first developing economy-focused, full-stack climate tech company that aims to offset 
        greenhouse emissions by incentivizing carbon-neutral nature-based practices. It helps multinationals 
        address their net zero emission targets by connecting them with carbon removal agriculture, forestry, 
        and agroforestry projects from India, South Asia, South-East Asia, and Sub-Saharan Africa. The company provides end-to-end assistance by being a one-stop destination for 
        the creation, quantification, verification, & sale of high-quality carbon credits besides being 
        en route to sequestering over a billion tonnes of carbon by 2030."
      />
      <div className="portfolio__content">
        <div className="varaha-container">
          <Images image={banner} fullwidth />
          <div className="primary padding__top padding__bottom -mt-1">
            <Images image={Group} />
          </div>
          <div className="product-logo-wrapper flex-wrapper primary-gradient">
            <img className="image" src={logo} />
          </div>
          <div className="product-grid -mt-1">
            <Images image={productGrid} fullwidth />
          </div>
          <div className="flex-wrapper secondary -mt-1">
            <img className="image" src={logoDesign} />
          </div>
          <div>
            <Images image={logoOptions} className="mt-1" fullwidth />
          </div>
          <div className="varaha-typography-wrapper primary -mt-1 ">
            <div className="alignment__container2 padding__top ">
              <h3 className="">
                Brand <br /> Elements
              </h3>
              <br />
              <br />
              <br />

              <hr />
              <h4>Typography</h4>
              <hr />

              <div className="fonts-grid">
                <div>
                  <h6>PRIMARY</h6>
                  <h4>Plus Jakarta Sans</h4>
                </div>

                <div>
                  <h6>SECONDARY</h6>
                  <h4>Souvenir</h4>
                </div>
              </div>

              <div className="margin__top">
                <Images image={typography} fullwidth />
                <Images image={fonts_sample} fullwidth />
                <div className="margin__top">
                  <Images
                    image={patternslogic}
                    fullwidth
                    className="margin__top"
                  />
                  <Images
                    image={logoview}
                    fullwidth
                    className="-mt-1 padding__bottom"
                  />
                </div>
              </div>
            </div>
            <div className="primary-light ">
              <div className="alignment__container2 padding__top">
                <h3>BRANDING</h3>
                <br />
                <p className="dashed-line" />

                <br />
                <br />
                <br />

                <div className="flexwrap margin__top">
                  <Images image={brandingbook} fullwidth />
                  <div className="flex -mt-1">
                    <Images image={brandingvehicle} fullwidth />
                    <Images image={brandingcards} fullwidth />
                  </div>
                  <Images image={brandingphoneview} fullwidth />
                  {/* <div className="identity primary-gradient">
                    <h3>
                      With a new name, VARAHA needed an identity that
                      established it as a trusted technology partner and
                      innovator in the cloud-management space.
                    </h3>
                  </div> */}
                  <Images
                    image={brandinglibrary}
                    fullwidth
                    className="-mt-1 padding__bottom"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="interactive-wrapper padding__bottom">
            <div className="alignment__container2 ">
              <h3 className="">Web Design</h3>
              <p className="dashed-line" />
            </div>
          </div>
          <Images image={productOverview} fullwidth />
          {/*  mission */}
          {/* <div className="mission-wrapper -mt-1">
            <h3>MISSION</h3>
            <h4>See the sustainability impact of Apeel-Protected Produce.</h4>
          </div> */}
          {/* Homepage */}
          <div className="padding__top padding__bottom primary">
            <div className="alignment__container2">
              <Images image={homepage} className="-mt-1 " />
            </div>
          </div>
          <div className="page__view padding__bottom padding__top primary-gradient">
            <div className="alignment__container2 flex-sm">
              <Images image={about} fullwidth className="-mt-1 about-us" />
              <Images image={business} fullwidth className="-mt-1 business" />
            </div>
          </div>
          <div className="padding__top  primary">
            <div className="alignment__container2 flexwrap">
              <Images image={partners} fullwidth className="-mt-1 " />
              <div className="flex -mt-1">
                <Images image={quotes} fullwidth />
                <Images image={ideas} fullwidth />
              </div>
              <Images image={career} fullwidth className="-mt-1 " />
              <Images image={allpages} fullwidth className="-mt-1 " />
            </div>
            <Images
              image={responsiveview}
              fullwidth
              className="-mt-1 padding__top"
            />
          </div>
          <div className="varaha__tnx padding__top padding__bottom sec-gradient -mt-1">
            <div>
              {/* <h4 data-aos="fade-up">Animals Production System</h4> */}
              <Images image={logoTitle} className="title" />
              {/* <h4 data-aos="fade-up">
                Managing system for small & medium animal production farms
              </h4>
              <h4 data-aos="fade-up">All rights reserved.</h4>
              <h4 data-aos="fade-up">Middle east, Kuwait, Salhia</h4> */}
              <br />
              {/* <Images image={logoChakraGreen} className="chakra" /> */}
            </div>
          </div>

          <PrevAndNextWork
            prev="/work/vijay-chat-house"
            next="/work/encompass"
            image1={vijay}
            image2={encompass}
          />
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default Varaha;
