import banner from "../assets/cost_it_right/banner.png";
import logo_design_1 from "../assets/cost_it_right/logo_design_1.png";
import logo_design_2 from "../assets/cost_it_right/logo_design_2.png";
import bag_design from "../assets/cost_it_right/bag_design.png";
import banner_design_1 from "../assets/cost_it_right/banner_design_1.png";
import banner_group from "../assets/cost_it_right/banner_group.png";
import book_cover from "../assets/cost_it_right/book_cover.png";
import book_design from "../assets/cost_it_right/book_design.png";
import buidlign_banner_2 from "../assets/cost_it_right/buidlign_banner_2.png";
import building from "../assets/cost_it_right/building.png";
import card_light from "../assets/cost_it_right/card_light.png";
import laptop_banner_2 from "../assets/cost_it_right/laptop_banner_2.png";
import laptop_mockup from "../assets/cost_it_right/laptop_mockup.png";
import logo_group from "../assets/cost_it_right/logo_group.png";
import sidewall_design from "../assets/cost_it_right/sidewall_design.png";
import roadside_banner from "../assets/cost_it_right/roadside_banner.png";
import shirt_logo from "../assets/cost_it_right/shirt_logo.png";
import visitng_card_design from "../assets/cost_it_right/visitng_card_design.png";
import wall_design from "../assets/cost_it_right/wall_design.png";
import logo_center from "../assets/cost_it_right/logo_center.png";

import logo_white from "../assets/cost_it_right/logo_white.png";
import logo_black from "../assets/cost_it_right/logo_black.png";
import logo_green from "../assets/cost_it_right/logo_green.png";
import logo_blue from "../assets/cost_it_right/logo_blue.png";

import Image from "../Components/Atoms/Images";
import PortfolioHeading from "../Components/PortfolioHeading";
import Footer from "../Components/Footer";
export default function CostItRight() {
  return (
    <section className="portfolio__container">
      <PortfolioHeading
        heading="Cost It Right"
        subHeading="Branding"
        info="Cost It Right is a costing estimation and automation software built specifically for automobile manufacturers to automate and streamline their costing management processes.
        "
        infoSecondary="CIR joined hands with Blacksof to build a unique brand identity that advantages its positioning. We designed their brand mark and theme along with a range of marketing collaterals to support their sales initiatives. 
        "
      />

      <div className="portfolio__content">
        <div className="top__margin">
          <Image image={banner} />
        </div>
        {/* <Image image={logo_design_1} />
        <Image image={logo_design_2} /> */}
        <div className="simple__width top__margin">
          <div className="flex__wrapper">
            <img src={logo_center} />
            <div className="grid">
              <img src={logo_white} alt="" />
              <img src={logo_black} alt="" />
              <img src={logo_green} alt="" />
              <img src={logo_blue} alt="" />
            </div>
          </div>
          {/* <div className="video__wrapper">video will come here</div> */}
        </div>

        {/* <div>placeholder blade</div> */}
        <div className="top__margin">
          <Image image={logo_group} />
        </div>
        {/* <div>placeholder blade</div> */}
        <div className="top__margin">
          <Image image={laptop_banner_2} />
        </div>
        <div className="top__margin">
          <Image image={visitng_card_design} />
        </div>
        <div className="top__margin">
          <Image image={book_cover} />
        </div>
        <div className="top__margin">
          <Image image={banner_design_1} />
        </div>
        <div className="top__margin">
          <Image image={book_design} />
        </div>
        <div className="top__margin">
          <Image image={card_light} />
        </div>
        <div className="top__margin">
          <Image image={laptop_mockup} />
        </div>
        <div className="top__margin">
          <Image image={banner_group} />
        </div>
        <div className="top__margin">
          <Image image={bag_design} />
        </div>
        <div className="top__margin">
          <Image image={sidewall_design} />
        </div>
        <div className="top__margin">
          <Image image={buidlign_banner_2} />
        </div>
        <div className="top__margin">
          <Image image={wall_design} />
        </div>
        <div className="top__margin">
          <Image image={roadside_banner} />
        </div>
        <div className="top__margin">
          <Image image={shirt_logo} />
        </div>
        <div className="top__margin">
          <Image image={building} />
        </div>
        {/* <div className="thanks__wrapper">
          <h3>Thanks</h3>
          <span>
            We appreciate you for going through our UI/UX Design work. Want to
            look at more of our work?
          </span>
        </div> */}

        <Footer />
      </div>
    </section>
  );
}
