import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import ima from "../assets/images/Ima.jpg";
import sleek from "../assets/images/sleek_new.jpg";
import PortfolioHeading from "../Components/PortfolioHeading";
import Logo from "../assets/slsAssets/sls_logo.svg";
import Computer from "../assets/slsAssets/viasights.svg";
import Sun from "../assets/slsAssets/sunsight.svg";
import Yalgaar from "../assets/slsAssets/yalgaar.svg";
import Loc from "../assets/slsAssets/loc.svg";
import Varde from "../assets/slsAssets/verde.svg";
import Images from "../Components/Atoms/Images";
import Man from "../assets/slsAssets/backpic.png";
import Our from "../assets/slsAssets/ourattitude.png";
import Flow from "../assets/slsAssets/flow.svg";
import Container from "../assets/slsAssets/container.png";
import Cards from "../assets/slsAssets/cards.png";
import Collage from "../assets/slsAssets/collage.svg";
import Gridback from "../assets/slsAssets/gridbackground.png";
// import Blue from '../assets/slsAssets/background_blue.png';
// import Gridbottom from '../assets/slsAssets/gridbottom.png';
import Solar from "../assets/slsAssets/solar.png";
// import Solarwind from '../assets/slsAssets/solarandwind.png';
import Tablet from "../assets/slsAssets/tablet.png";
import Slsimg from "../assets/slsAssets/systemlevelsolution.png";
import Testing from "../assets/slsAssets/testing.png";
import TestingCalibration from "../assets/slsAssets/testing_and_calibration.png";
import Multiple from "../assets/slsAssets/multiplepics.png";
import System from "../assets/slsAssets/systemlevel.png";
// import Grid from '../assets/slsAssets/grid.svg';
import Thanks from "../assets/slsAssets/thanks.png";
import Screens from "../assets/slsAssets/40screens.png";
import About from "../assets/slsAssets/aboutus.png";
import Responsive from "../assets/slsAssets/responsive.png";
import Loccarto from "../assets/slsAssets/loccarto.png";
import AI from "../assets/slsAssets/ai.png";
import ESM from "../assets/slsAssets/ems.png";
import Pcb from "../assets/slsAssets/pcb_design.png";
import Softwaredev from "../assets/slsAssets/software_development.png";
import Rectangle from "../assets/slsAssets/rectangle.svg";
import PrevAndNextWork from "../Components/PrevAndNextWork";
import Footer from "../Components/Footer";

const Sls = () => {
  useEffect(function () {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="portfolio__container">
      <PortfolioHeading
        heading="SLS"
        subHeading="UI/UX"
        info="System Level Solutions is a 21-year-old technology conglomerate that provides turnkey solutions in the domains of intellectual property, product and software development, industrial design, manufacturing, etc. This along with heading an array of sub-brands in innovative categories. "
        infoSecondary="As part of their 3-year-long brand collaboration with Blacksof, SLS wanted us to redesign their 
        existing website to present their industry stronghold and diverse portfolio in a systematic and simple manner. 
        We designed a mother website that showcased their 6 core services and 10 sister brands right from wireframing to 
        design and deployment. 
        "
      />
      <div className="portfolio__content">
        <div className="MyStyle ">
          <div className="MyImg ">
            <img className="image" data-aos="fade-up" src={Logo} alt="" />
          </div>
          <div className="Logos_wrapper" data-aos="fade-up">
            <img className="image" src={Computer} alt="logo" />
            <img className="image" src={Sun} alt="sun" />
            <img className="image" src={Yalgaar} alt="yal" />
            <img className="image" src={Loc} alt="loc" />
            <img className="image" src={Varde} alt="Var" />
          </div>
        </div>
        {/* <div>
          <img
            className="Man_banner"
            src={Man}
            data-aos="fade-up"
            fullwidth
            alt="background"
          />
        </div> */}
        {/* <div className="background_container">
          <div className="style_container " data-aos="fade-up">
            <h4>WHAT WE CAN HANDLE</h4>
            <br></br>
            <br></br>
            <p>
              Donsectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat.
            </p>
            <br></br>
            <p>
              Donsectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam.
            </p>
          </div>
          <div className="para_container" data-aos="fade-up">
            <p>E-commerce shipments</p>
            <br></br>
            <p>Door to door services with import & export</p>
            <br></br>
            <p>Custom clearance</p>
            <br></br>
            <p className="element_container">Website redesign</p>
            <br></br>
            <p>Trucking</p>
            <br></br>
            <p>Labeling & bar coding</p>
            <br></br>
            <p>Distribution</p>
            <br></br>
            <p>Special packing</p>
            <br></br>
          </div>
        </div> */}
        {/* <div className="our_container" data-aos="fade-up">
          <Images image={Our} alt="our" />
        </div> */}
        <div className="background_container" data-aos="fade-up">
          <div className="backgroundblack ">
            <h3 className="wordh3">
              For 30 years, We set ourselves apart through our experts'
              expertise and experience.
            </h3>
          </div>
          <div className="backgroundblue">
            <p className="wordp">
              Each of our advisors has a long track record that attests to the
              experience and expertise that they bring to SMEs with growth and
              performance objectives.
            </p>
          </div>
        </div>
        <section className="black_container">
          <div className="subpara_wrapper" data-aos="fade-up">
            <div className="textStyle">
              <h3 className="font">User Flow</h3>
            </div>
          </div>
          <Images className="flow" image={Flow} alt="flow" />
        </section>
        <div className="subpara_wrapper" data-aos="fade-up">
          <div className="textStyle">
            <h3>Styleguide</h3>
          </div>
        </div>
        <Images image={Container} fullwidth alt="container" />
        <Images image={Cards} fullwidth alt="cards" />
        <div className="art-container" data-aos="fade-up">
          <div className="first_grid">
            <div className="text-para">
              <h4 className="color_sls">
                All icons are drawn <br></br> on a pixel-based <br></br>grid
              </h4>
            </div>
            <p className="para-art">
              Universal icon set is drawn on a pixel-based grid <br></br>of 24px
              X 24px and scaled-up linearly to different <br></br> sizes. The
              grid contains 2px padding for the <br></br>surrounding live area.
            </p>
            <div className="grid">
              <p>
                <b>24 X 24 px</b> <b className="gap">2 px Safe</b>
              </p>
              <p>
                Grid <em className="gap_second">padding</em>
              </p>
            </div>
          </div>
          <div className="second_grid">
            <img className=" rectangle_grid" src={Rectangle} alt="flow" />
          </div>
        </div>
        <div className="collage">
          <Images image={Collage} fullwidth alt="collage" />
        </div>
        <div className="gridbackground" data-aos="fade-up">
          <div>
            <img className="gridback" src={Gridback} alt="grid" />
          </div>
          <div className="grid_text">
            <h2>Grid System</h2>
            <br></br>
          </div>
        </div>
        <div className="subpara_wrapper" data-aos="fade-up">
          <div className="textStyle">
            <h3>Home</h3>
          </div>
        </div>

        <Images image={Slsimg} fullwidth alt="Sls" />

        <div className="subpara_wrapper" data-aos="fade-up">
          <div className="textStyle">
            <h3>Industries</h3>
          </div>
        </div>
        <Images image={Solar} fullwidth alt="Solar" />
        <div className="subpara_wrapper" data-aos="fade-up">
          <div className="textStyle">
            <h3>Services</h3>
          </div>
        </div>
        <div className="testing_container">
          <Images image={Testing} fullwidth alt="testing" />
        </div>
        <img
          className="tesingCalibration"
          src={TestingCalibration}
          alt="tesingCalibration"
        />
        <div className="grey_container" data-aos="fade-up">
          <img className="services_container1" src={AI} alt="ai" />
          <img className="services_container2" src={ESM} alt="esm" />
          <img className="services_container3" src={Pcb} alt="pcb" />
          <img
            className="services_container4"
            src={Softwaredev}
            alt="software_deveploment"
          />
        </div>
        <Images image={Multiple} fullwidth alt="multiple" />

        <section data-aos="fade-up">
          <Images image={About} fullwidth alt="About us" />
          <Images image={Responsive} fullwidth alt="Responsive" />
          <Images image={Loccarto} fullwidth alt="loccatro" />
          <Images image={Screens} fullwidth alt="sytemlevel" />
          <Images image={System} alt="sytemlevel" />
          <Images image={Thanks} fullwidth alt="thank you" />
        </section>

        <PrevAndNextWork
          prev="/work/vijay-chat-house"
          next="/work/sleek"
          image1={ima}
          image2={sleek}
        />
        <Footer />
      </div>
    </div>
  );
};
export default Sls;
