import React from "react";

const PortfolioHeading = ({
  heading,
  subHeading,
  info,
  id,
  numbers,
  infoSecondary,
}) => {
  return (
    <div className={`bg-dark h-[100vh] min-h-fit ${!info && "half__height"}`}>
      <div
        className={`portfolio__heading__container ${!info && "half__height"}`}
        id={id}
        data-aos="fade-up"
      >
        <div className={`portfolio__heading ${!info && "heading__center"}`}>
          <div
            className={`heading__bunch ${!info && "heading__bunch__center"}`}
          >
            <div className="heading__wrapper">
              <h4>{heading} </h4>
              {numbers ? (
                <h4 style={{ marginBottom: "14px" }}>{numbers}</h4>
              ) : (
                ""
              )}
            </div>
            <h5 className="text-lightGray">{subHeading}</h5>
          </div>
          {info && (
            <div className="portfolio__para">
              <p>{info}</p>
              <p className="text-lightGray secondaryHeading">{infoSecondary}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioHeading;
