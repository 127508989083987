import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

//components
import Encompass from "../Portfolios/Encompass";
import IMA2019 from "../Portfolios/IMA2019";
import IMA2020 from "../Portfolios/IMA2020";
import InformaticsPress from "../Portfolios/InformaticsPress";
import ITAT from "../Portfolios/ITAT";
import Sinless from "../Portfolios/Sinless";
import Sleek from "../Portfolios/Sleek";
import VijayChatHouse from "../Portfolios/VijayChatHouse";
import Brebel from "../Portfolios/Brebel";
import Spintly from "../Portfolios/Spintly";
import Senstra from "../Portfolios/Senstra";
import Evre from "../Portfolios/Evre";
import EvreBranding from "../Portfolios/EvreBranding";
import Gavyam from "../Portfolios/Gavyam";
import Error from "./Error";
import AuBank from "../Portfolios/AuBank";
import ShopX from "../Portfolios/ShopX/ShopX";
import Sls from "../Portfolios/Sls";
import DN99 from "../Portfolios/DN99";
import OKAS from "../Portfolios/OKAS";
import Varaha from "../Portfolios/Varaha";
import Verdemobility from "../Portfolios/VerdemobilityBranding";

import Rudo from "../Portfolios/Rudo";
import Swan from "../Portfolios/Swan";
import ShopXApp from "../Portfolios/ShopXApp";
import Fasal from "../Portfolios/Fasal";
import BrebelBranding from "../Portfolios/BrebelBranding";
import VerdemobilityBranding from "../Portfolios/VerdemobilityBranding";
import VerdemobilityApp from "../Portfolios/VerdemobilityApp";
import CostItRight from "../Portfolios/CostItRight";

const ResourcesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", (event) => {
      const portfolioContent =
        document.getElementsByClassName("portfolio__content");
      portfolioContent.style.transform = `translateY(${window.scrollY})`;
    });
  }, []);

  return (
    <div>
      <Switch>
        <Route path="/work/informatic-Press">
          <InformaticsPress />
        </Route>
        <Route path="/work/ima2020">
          <IMA2020 />
        </Route>
        <Route path="/work/encompass">
          <Encompass />
        </Route>
        <Route path="/work/sinless">
          <Sinless />
        </Route>
        <Route path="/work/sleek">
          <Sleek />
        </Route>
        <Route path="/work/ima2019">
          <IMA2019 />
        </Route>
        <Route path="/work/itat">
          <ITAT />
        </Route>
        <Route path="/work/vijay-chaat-house">
          <VijayChatHouse />
        </Route>
        <Route path="/work/brebel">
          <Brebel />
        </Route>
        <Route path="/work/brebel-branding">
          <BrebelBranding />
        </Route>
        <Route path="/work/spintly">
          <Spintly />
        </Route>
        <Route path="/work/senstra">
          <Senstra />
        </Route>
        <Route path="/work/evre">
          <Evre />
        </Route>
        <Route path="/work/evre-branding">
          <EvreBranding />
        </Route>
        <Route path="/work/gavyam">
          <Gavyam />
        </Route>
        <Route path="/work/aubank">
          <AuBank />
        </Route>
        <Route path="/work/shopx">
          <ShopX />
        </Route>
        <Route path="/work/shopx-app">
          <ShopXApp />
        </Route>
        <Route path="/work/sls">
          <Sls />
        </Route>
        <Route path="/work/rudo">
          <Rudo />
        </Route>
        <Route path="/work/dn99">
          <DN99 />
        </Route>
        <Route path="/work/okas">
          <OKAS />
        </Route>
        <Route path="/work/varaha">
          <Varaha />
        </Route>
        <Route path="/work/fasal">
          <Fasal />
        </Route>
        <Route path="/work/verdemobility-branding">
          <VerdemobilityBranding />
        </Route>
        <Route path="/work/verdemobility-app">
          <VerdemobilityApp />
        </Route>
        <Route path="/work/cost-it-right">
          <CostItRight />
        </Route>
        <Route path="/work/swan">
          <Swan />
        </Route>
        <Route path="*">
          <Error />
        </Route>
      </Switch>
    </div>
  );
};

export default ResourcesPage;
