import React from "react";

const PrevAndNextWork = ({ next, prev, image1, image2 }) => {
  return (
    <div>
      {/* <div className="alignment__container2" data-aos="fade-up">
        <div className="portfolio__container">
          <div className="prev_next_work_container ">
            <a rel="noreferrer" href={prev} className="prev_next_work_box_left">
              <h3>Previous Work</h3>

              <div className="image_bg">
                <img className="images" src={image1} alt="Previous Work" />
              </div>
            </a>

            <div className="prev_hr"></div>

            <a
              rel="noreferrer"
              href={next}
              className="prev_next_work_box_right"
            >
              <h3>Next Work</h3>

              <div className="image_bg">
                <img className="images" src={image2} alt="Next Work" />
              </div>
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PrevAndNextWork;
