import { React, useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";
import PortfolioHeading from "../Components/PortfolioHeading";
import PrevAndNextWork from "../Components/PrevAndNextWork";
import Footer from "../Components/Footer";
import ReactPlayer from "react-player";
import vijay from "../assets/images/VCH.jpg";
import encompass from "../assets/images/encompass.jpg";
import Images from "../Components/Atoms/Images";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

import Fasal_logo from "../assets/fasalAssets/fasal_logo.png";
import Header from "../assets/fasalAssets/header.png";
import FasalVideo from "../assets/fasalAssets/fasalherovideobg.mp4";
import ColorBlack from "../assets/fasalAssets/colorblack.png";
import ColorGreen from "../assets/fasalAssets/colorgreen.png";
import ColorBlue from "../assets/fasalAssets/colorblue.png";
import ColorYellow from "../assets/fasalAssets/coloryellow.png";
import Fasal_icon from "../assets/fasalAssets/Group 7385.png";
import HighFidelity from "../assets/fasalAssets/Group 7386.png";
import Side_img1 from "../assets/fasalAssets/Group 7340.svg";
import Side_img2 from "../assets/fasalAssets/Group 7358.svg";
import Instagram1 from "../assets/fasalAssets/Instagram1.png";
import Instagram2 from "../assets/fasalAssets/Instagram2.png";
import Instagram3 from "../assets/fasalAssets/Instagram3.png";
import Instagram4 from "../assets/fasalAssets/Instagram4.png";
import Instagram5 from "../assets/fasalAssets/Instagram5.png";
import Instagram6 from "../assets/fasalAssets/Instagram6.png";
import instagramcarousel1 from "../assets/fasalAssets/Instagramcaruosel1.png";
import instagramcarousel3 from "../assets/fasalAssets/Instagramcaruosel3.png";
import Social_media_vedio from "../assets/fasalAssets/social_media_vedio.png";
import Instagram_post from "../assets/fasalAssets/instagram_post.mp4";

const Fasal = () => {
  useEffect(function () {
    Aos.init({ duration: 700 });
    var tl = gsap.timeline({ repeat: -1 });
    tl.to(".urbanist_wrapper h1", 25, { backgroundPosition: "-960px 0" });
  }, []);

  return (
    <div className="portfolio__container " data-aos="fade-up">
      <PortfolioHeading
        heading="Fasal"
        subHeading="UI/UX"
        info="Fasal is a leading AgriTech brand in India solving the deeply rooted challenges of the Indian horticulture value chain. 
        They initially partnered with Blacksof to champion their SM initiatives with strategy and design-focused interventions. 
        "
        infoSecondary="The target bringing them closer to the farmer community across India. Later, a handful of other activities, right from SEM to the creation of vernacular landing pages, and a lot more."
      />
      <div className="portfolio__content">
        <section className="fasal_hero_section">
          {/* <div className="hero_section">
          <div className="video_section">
            <img className="header_img" src={Header} alt="img" />
            <div className="fasal_vedio">
              <ReactPlayer
                url={FasalVideo}
                loop
                pip
                playing
                muted
                playsinline
                width="64vw"
                height="100%"
              />
            </div>
          </div>
        </div> */}
          <div className="background_section">
            <div className="video_section" data-aos="zoom-in-up">
              <img className="header_img" src={Header} alt="img" />
              <ReactPlayer
                url={FasalVideo}
                loop
                pip
                playing
                muted
                playsinline
                width="57vw"
                height="100%"
                className="fasal_vedio"
              />
            </div>
          </div>
        </section>

        <section className="fasal_colorcode">
          <div className="fasal_cards" data-aos="fade-up">
            <div className="fasal_green">
              <img className="fasal_logo" src={Fasal_logo} alt="img" />
            </div>
            <div className="fasal_white">
              <img className="fasal_logo" src={Fasal_logo} alt="img" />
            </div>
            <div className="fasal_brown">
              <img className="fasal_logo" src={Fasal_logo} alt="img" />
            </div>
            <div className="fasal_light">
              <img className="fasal_logo" src={Fasal_logo} alt="img" />
            </div>
            <div className="fasal_beige">
              <img className="fasal_logo" src={Fasal_logo} alt="img" />
            </div>
            <div className="fasal_black">
              <img className="fasal_logo" src={Fasal_logo} alt="img" />
            </div>
          </div>
        </section>
        <div className="colorpalette" data-aos="fade-up">
          <h3>COLOR PALETTE</h3>
          <h1>COLOR PALETTE</h1>
          <div className="colorimg">
            <div>
              <img className="color_green" src={ColorBlack} alt="img" />
              <h5>#2A2A2A</h5>
            </div>
            <div>
              <img className="color_green" src={ColorGreen} alt="img" />
              <h5>#00613C</h5>
            </div>
            <div>
              <img className="color_green" src={ColorYellow} alt="img" />
              <h5>#FFE366</h5>
            </div>
            <div>
              <img className="color_green" src={ColorBlue} alt="img" />
              <h5>#4D9DE4</h5>
            </div>
          </div>
        </div>

        <section className="urbanist_container">
          <div className="typography" data-aos="fade-up">
            <h4>TYPOGRAPHY</h4>
            <h3>
              Welcome to the Future of<br></br> Horticulture Farming!
            </h3>
            <div className="urbanist_wrapper">
              <h1 className="title">Urbanist</h1>
            </div>
            <h3 className="urbanist_letter">
              Aa Bb Cc Dd Ee Ff Gg Hh Ii <br></br>Jj Kk Ll Mm Nn Oo Pp Qq Rr
              <br></br> Ss Tt Uu Vv Ww Xx Yy Zz <br></br>1 2 3 4 5 6 7 8 9 0
              <br></br> @ # $ % & ! / ~
            </h3>
          </div>
        </section>
        <div className="colorpalette" data-aos="fade-up">
          <h3>ICONS STYLE</h3>
          <h1>ICONS STYLE</h1>
          <img className="fasal_icon" src={Fasal_icon} alt="img" />
        </div>
        <section className="screen_wrapper">
          <div className="screens" data-aos="fade-up">
            <h4>HIGH FIDELITY</h4>
            <div className="screen_border">
              <span className="vertical_line"></span>
              <h2>Screens</h2>
            </div>
          </div>
          <img className="highfidelity" src={HighFidelity} alt="img" />
        </section>
        <section className="instagram_wrapper">
          <div className="screens" data-aos="fade-up">
            <h4>SOCIAL MEDIA</h4>
            <div className="screen_border">
              <span className="vertical_line"></span>
              <h2>Instagram carousel</h2>
            </div>
          </div>
          <img className="side_img" src={Side_img1} alt="img" />
          <img className="side_left_img" src={Side_img2} alt="img" />
          <div className="instagram_carousel" data-aos="fade-up">
            <img
              className="instagram_carousel_img_one"
              src={instagramcarousel1}
              alt="img"
            />
          </div>
          <div className="instagram_carousel_two" data-aos="fade-up">
            <img
              className="instagram_carousel_img_three"
              src={instagramcarousel3}
              alt="img"
            />
          </div>
        </section>
        <section className="sm_wrapper  ">
          <div className="screens_social" data-aos="fade-up">
            <h4>SOCIAL MEDIA</h4>
            <div className="screen_border">
              <span className="vertical_line_bggreen"></span>
              <h2>Social media post</h2>
            </div>
          </div>
          <div className="instagram_post " data-aos="fade-up">
            <img className="instagram1" src={Instagram1} alt="img" />
            <img className="instagram1" src={Instagram2} alt="img" />
            <img className="instagram1" src={Instagram3} alt="img" />
          </div>
          <div className="instagram_post2" data-aos="fade-up">
            <img className="instagram1" src={Instagram4} alt="img" />
            <img className="instagram1" src={Instagram5} alt="img" />
            <img className="instagram1" src={Instagram6} alt="img" />
          </div>
        </section>
        <section className="Social_media_container">
          <div className="screens" data-aos="fade-up">
            <h4>SOCIAL MEDIA</h4>
            <div className="screen_border">
              <span className="vertical_line"></span>
              <h2>Social video</h2>
            </div>
          </div>
          <div className="instagram_post_video_wrapper " data-aos="fade-up">
            <img
              className="social_media_vedio"
              src={Social_media_vedio}
              alt="img"
            />
            <div className="instagram_post_video">
              <video muted autoPlay loop>
                <source src={Instagram_post} type="video/mp4" />
              </video>
            </div>
          </div>
        </section>
        {/* <div className="fasal_thankyou">
          <h3 data-aos="fade-up">
            Thank you <br></br> for watching
          </h3>
        </div> */}

        <PrevAndNextWork
          prev="/work/rudo"
          next="/work/encompass"
          image1={vijay}
          image2={encompass}
        />
        <Footer />
      </div>
    </div>
  );
};

export default Fasal;
