import React from "react";

//icons
import logo from "../assets/images/blacksofLogo.png";

const Footer = () => {
  return (
    <div className="footer__wrapper">
      <footer className="text-lightGray bg-dark pt-44 pb-6 md:pb-8 lg:pb-12 xl:pb-16 selection:bg-white selection:text-dark">
        <div>
          <p className="font-satoshi font-bold sm:text-4xl xl:text-4xl 2xl:text-5xl text-lightGray text-center px-3 lg:pb-1">
            Want to know us closer?
          </p>
          <p
            className="font-satoshi sm:mt-1 2xl:mt-4 text-base sm:text-2xl md:text-2xl xl:text-xl 2xl:text-2xl 
          text-opacity-60 text-lightGray text-center px-10 pb-10 md:pb-16"
          >
            Let&apos;s unfold a new chapter of growth together.
          </p>
          <div className="text-center px-3">
            <a
              href="https://blacksof.com/get-in-touch"
              className="sweep_to_right stroke-white border-1 border-lightGray border-solid text-lightGray   font-satoshi-bold text-base px-6 py-3 gap-3 inline-flex flex-nowrap items-center "
            >
              Get In Touch
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 256"
                height="24"
                width="24"
                className="-rotate-45 group-hover:rotate-0 transition-all duration-300 ease-in-out"
              >
                <line
                  x1="40"
                  x2="216"
                  y1="128"
                  y2="128"
                  fill="none"
                  stroke="inherit"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></line>
                <polyline
                  fill="none"
                  stroke="inherit"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                  points="144 56 216 128 144 200"
                ></polyline>
              </svg>
            </a>
          </div>
          <div className="flex items-center justify-center px-3 md:mt-20 mt-20 lg:mt-36 xl:mt-44 2xl:mt-52 mb-5">
            <img src={logo} alt="Blacksof Logo" className="h-20" />
          </div>
          <div
            className="flex justify-between gap-y-6
          gap-x-6 lg:flex-row flex-col items-center
            py-4 max-w-[1990px] mx-auto md:w-11/12  2xl:w-10/12 px-5
           border-b-1 border-solid border-lightGray border-opacity-10"
          >
            <div className="lg:basis-96 grow shrink flex items-center gap-y-2 gap-x-4 font-satoshi">
              <a
                href="https://blacksof-astro.vercel.app/about"
                className="font-satoshi text-opacity-60  hover:text-opacity-100  text-lightGray [object Object]"
              >
                About
              </a>
              <a
                href="https://blacksof-astro.vercel.app/services"
                className="font-satoshi text-opacity-60  hover:text-opacity-100  text-lightGray [object Object]"
              >
                Services
              </a>
              <a
                href="https://blacksof-astro.vercel.app/work"
                className="font-satoshi text-opacity-60  hover:text-opacity-100  text-lightGray [object Object]"
              >
                Work
              </a>
              <a
                href="https://blacksof-astro.vercel.app/think"
                className="font-satoshi text-opacity-60  hover:text-opacity-100  text-lightGray [object Object]"
              >
                Think
              </a>
              <a
                href="https://blacksof-astro.vercel.app/culture"
                className="font-satoshi text-opacity-60  hover:text-opacity-100  text-lightGray [object Object]"
              >
                Culture
              </a>
              <a
                href="https://blacksof-astro.vercel.app/get-in-touch"
                className="font-satoshi text-opacity-60  hover:text-opacity-100  text-lightGray [object Object]"
              >
                Get In Touch
              </a>
            </div>
            <span className="font-satoshi-light mt-3 lg:mt-0 text-lightGray text-sm text-opacity-60">
              Copyright 2023 @Blacksof
            </span>
            <ul
              className="lg:basis-96 grow shrink flex 
            flex-nowrap items-center gap-4 
            lg:justify-end justify-center font-satoshi"
            >
              <li>
                <a
                  target="_blank"
                  rel="author"
                  className="text-lightGray font-satoshi font-light text-opacity-60 hover:text-opacity-100 text-base"
                  href="https://twitter.com/blacksof_design?s=09"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="author"
                  className="text-lightGray font-satoshi font-light text-opacity-60 hover:text-opacity-100 text-base"
                  href="https://www.linkedin.com/company/blacksof/mycompany/"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="author"
                  className="text-lightGray font-satoshi font-light text-opacity-60 hover:text-opacity-100 text-base"
                  href="https://www.instagram.com/blacksof_design/?igshid=dfk0aou5siq8"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
